<template>
  <div id="music-component" class="component with-padding">
    <div id="info-icon" class="info">
      <div class="component-icon headphones-icon"></div>
      <router-link to="/music"><span class="info-label">Music</span></router-link>
    </div>
    <div class="soundcloud-embed">
      <iframe id="sc-widget" width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" :src="musicSrc"></iframe>
      <!-- <iframe id="sc-widget" width="100%" height="700" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1250020246%3Fsecret_token%3Ds-zZjb0rDGIu4&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/wearesuperok" title="Super OK" target="_blank" style="color: #cccccc; text-decoration: none;">Super OK</a> · <a href="https://soundcloud.com/wearesuperok/sets/secret-demos/s-zZjb0rDGIu4" title="Secret Demos" target="_blank" style="color: #cccccc; text-decoration: none;">Secret Demos</a></div> -->
    </div>
    <div class="player-container">
      <div class="player">
        <div class="song-cover" :id="mapNameToImage(activeSong.title)"></div>
        <div class="others">
          <div class="song-details">
            <div class="play-pause-button">
              <div v-if="init">
                <div v-on:click="playPause()" class="action-button-container" style="margin-right: 15px;">
                  <div class="action-button-icon">
                    <div v-if="state === 'play'" class="icon" id="pause-icon"></div>
                    <div v-else class="icon" id="play-icon"></div>
                  </div>
                </div>
              </div>
              <div v-else class="action-button-container" style="margin-right: 15px;"></div>
            </div>
            <div class="song-name">{{activeSong.title}}</div>
          </div>
          <div class="player-controls"></div>
          <div class="progress-bar">
            <div>
              <div>
                <div class="label">
                  <div class="time">{{ `${msToTime(currentTime)} / ${msToTime(activeSong.duration)}` }}</div>
                  <div class="volume-button" v-on:click="toggleVolume()">
                    <span v-if="isMuted">
                      <i class="im im-volume-off"></i>
                    </span>
                    <span v-else>
                      <i class="im im-volume"></i>
                    </span>
                  </div>
                </div>
              </div>
              <input style="--min: 0; --val: 0" class="styled-slider slider-progress" v-on:change="setTime" v-on:mousedown="pauseInputUpdate" @touchstart="pauseInputUpdate" id="song" type="range" min="0" :max="activeSong.duration" ref="progress">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="song-list">
      <ul>
        <li class="song" v-for="song in songs" :key="song.name">
          <div class="action-button-container" style="margin-right: 30px;" v-on:click="setSong(song)">
            <div class="action-button-icon">
              <div v-if="state === 'play' && song.id === activeSong.id" class="icon" id="pause-icon"></div>
              <div v-else class="icon" id="play-icon"></div>
            </div>
          </div>
          <div class="song-name">{{ song.title }}</div>
          <div class="song-length"><i>{{ msToTime(song.duration) }}</i></div>
        </li>
      </ul>
    </div>
    <div id="powered-by-soundcloud-container">
      <a target="_blank" href="https://soundcloud.com/wearesuperok">
        <img id="powered-by-soundcloud-pic" src="../assets/powered_by_soundcloud.png" alt="Powered by SoundCloud">
      </a>
    </div>
  </div>
</template>

<script>
let widget;

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: 'Music',
  props: {
    musicSrc: String
  },
  mounted() {
    this.$refs.progress.value = 0;
    // https://developers.soundcloud.com/docs/api/html5-widget
    // link setup
    // eslint-disable-next-line no-undef
    widget = SC.Widget('sc-widget');
    // eslint-disable-next-line no-undef
    widget.bind(SC.Widget.Events.READY, () => this.onReady());
    // eslint-disable-next-line no-undef
    widget.bind(SC.Widget.Events.PLAY, () => this.setState('play'));
    // eslint-disable-next-line no-undef
    widget.bind(SC.Widget.Events.PAUSE, () => this.setState('pause'));
    // eslint-disable-next-line no-undef
    widget.bind(SC.Widget.Events.PLAY_PROGRESS, this.updateTime);
    // eslint-disable-next-line no-undef
    widget.bind(SC.Widget.Events.FINISH, this.onFinish);
    // https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1031581426&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&show_artwork=false&download=false&sharing=false&buying=false

    // https://css-tricks.com/sliding-nightmare-understanding-range-input/#the-range-progress-fill-component
    const _R = document.querySelector('[type=range]');
    document.documentElement.classList.add('js');
    _R.addEventListener('input', () => {
      _R.style.setProperty('--val',  _R.value)
    }, false);
  },
  beforeDestroy() {
    if (widget) {
      // this is ungraceful af but eff it
      try {
        // eslint-disable-next-line no-undef
        widget.unbind(SC.Widget.Events.READY);
        // eslint-disable-next-line no-empty
      } catch (error) {}
      try {
        // eslint-disable-next-line no-undef
        widget.unbind(SC.Widget.Events.PLAY);
        // eslint-disable-next-line no-empty
      } catch (error) {}
      try {
        // eslint-disable-next-line no-undef
        widget.unbind(SC.Widget.Events.PAUSE);
        // eslint-disable-next-line no-empty
      } catch (error) {}
      try {
        // eslint-disable-next-line no-undef
        widget.unbind(SC.Widget.Events.FINISH);
        // eslint-disable-next-line no-empty
      } catch (error) {}
      try {
        // eslint-disable-next-line no-undef
        widget.unbind(SC.Widget.Events.PLAY_PROGRESS);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  },
  data: () => ({
    init: false,
    firstPlayed: false,
    state: 'pause',
    currentTime: 0,
    activeSong: {},
    pauseUpdates: false,
    isMuted: false,
    songs: [],
  }),
  methods: {
    playPause() {
      if (!this.init || !this.state) {
        return;
      }
      widget.toggle();
      this.state = null;
    },
    setTime(event) {
      const newTime = Math.round(event.target.value);
      if (this.state === 'pause') {
        widget.play();
      }
      widget.seekTo(newTime);
      this.pauseUpdates = false;
    },
    updateTime(update) {
      this.currentTime = update.currentPosition;
      if (!this.pauseUpdates) {
        this.$refs.progress.value = this.currentTime;
        this.$refs.progress.style.setProperty('--val',  this.currentTime);
      }
    },
    async onReady() {
      let ready = false;
      let songs;
      while (!ready) {
        await delay(100);
        widget.getSounds(sounds => {
          ready = !sounds.find(s => s.title === undefined);
          songs = sounds;
        });
      }
      this.songs = songs;
      widget.getCurrentSound(activeSong => {
        this.activeSong = activeSong;
        this.resetSlider();
      });
      this.init = true;
    },
    setState(state) {
      if (state === 'play') {
        widget.getCurrentSound(activeSong => {
          if (activeSong.id !== this.activeSong.id) {
            widget.seekTo(0);
            this.activeSong = activeSong;
            this.resetSlider();
          }
        });
      }
      this.state = state;
    },
    msToTime(ms) {
      if (!ms) {
        return '0:00';
      }
      return new Date(ms).toISOString().slice(15, -5);
    },
    pauseInputUpdate() {
      this.pauseUpdates = true;
    },
    setSong(song) {
      if (song.id === this.activeSong.id) {
        widget.toggle();
        return;
      }
      if(this.state === "play") {
        widget.pause();
      }
      this.currentTime = 0;
      this.$refs.progress.value = 0;
      const newSongIndex = this.songs.findIndex(s => s.id === song.id);
      this.activeSong = song;
      widget.skip(newSongIndex);
      widget.seekTo(0);
      this.resetSlider();
    },
    toggleVolume() {
      if (this.isMuted) {
        widget.setVolume(100);
        this.isMuted = false;
      } else {
        widget.setVolume(0);
        this.isMuted = true;
      }
    },
    mapNameToImage(name) {
      switch(name) {
        case 'Seeing Illusions':
          return 'seeing-illusions-cover';
        case 'All Along':
          return 'all-along-cover';
        case 'Changes':
          return 'changes-cover';
        case 'Annoyed the Void':
          return 'annoyed-the-void-cover';
        case 'Break the Faith':
          return 'break-the-faith-cover';
        case 'Eat Us Alive':
          return 'eat-us-alive-cover';
        case 'Debt':
          return 'debt-cover';
        case '616':
          return 'six-sixteen-cover';
        default:
          return 'other-cover';
          // return 'no-cover';
      }
    },
    onFinish(details) {
      if (details.soundId === this.songs[this.songs.length - 1].id) {
        widget.skip(0);
      }
    },
    resetSlider() {
      this.$refs.progress.style.setProperty("--val", 0);
      this.$refs.progress.style.setProperty("--max", this.activeSong.duration);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .bandcamp-big-embeds {
    margin: 0 30px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    iframe {
      margin: 15px 10px;
    }
  }

  .embed {
    border: 0;
    width: 350px;
    height: 442px;
  }

  .soundcloud-embed {
    display: none;
  }

  .player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .player {
    // border: 1px solid rgba(44, 62, 80, .4);
    // border-bottom: none;
    width: 100%;
    max-width: 500px;
    display: flex;
  }

  .song-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
    ul {
      width: 100%;
      max-width: 500px;
    }
    .song {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(44, 62, 80, .4);
      padding: 0 10px;
      height: 70px;
      // justify-content: space-between;
      &:first-of-type {
        border-top: 1px solid rgba(44, 62, 80, .4);
      }
      .song-length {
        flex-grow: 0;
      }
    }
  }

  .song-name {
    font-size: 23px;
    font-weight: 400;
    flex-grow: 1;
  }

  .action-button-container {
    border: 2px solid rgb(44, 62, 80);
    border-radius: 50%;
    height: 36px;
    width: 36px;
    cursor: pointer;
    &:hover {
      -webkit-box-shadow: 1px 0px 6px 4px #828282;
      box-shadow: 1px 0px 6px 4px #828282;
    }
    .action-button-icon {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  .player {
    .song-cover {
      height: 200px;
      width: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 30%;
    }
    .others {
      flex-grow: 1;
      margin-top: 22px;
    }
    .song-details {
      display: flex;
      align-items: center;
      margin: 20px;
      .song-name {
        font-size: 28px;
      }
    }
  }

  .progress-bar {
    width: 100%;
    padding: 0 15px;
    height: 70px;
    progress {
      width: calc(100% - 30px);
    }
    input {
      width: calc(100% - 30px);
      outline: none !important;
      margin: 8px 0;
    }
  }

  progress[value] {
    // -webkit-appearance: none;
    // appearance: none;
    // background-color: white;
    color: blue;
    height: 20px;
  }

  #annoyed-the-void-cover {
    background-image: url('../assets/Annoyed_Option_7.jpg');
  }

  #changes-cover {
    background-image: url('../assets/changes.png');
  }

  #all-along-cover {
    background-image: url('../assets/AllAlong-Cover.jpg');
  }

  #seeing-illusions-cover {
    background-image: url('../assets/SeeingIllusions.jpg');
  }

  #break-the-faith-cover {
    background-image: url('../assets/Break-the-Faith-cover.jpg');
  }

  #debt-cover {
    background-image: url('../assets/debt-cover.jpg');
  }
  #six-sixteen-cover {
    background-image: url('../assets/616-cover.jpg');
  }
  #eat-us-alive-cover {
    background-image: url('../assets/Eat-Us-Alive-cover-smaller.jpg');
  }

  #other-cover {
    //background-image: url('../assets/SuperOK-blue-pink.jpg');
    background-image: url('../assets/Departures-Front-2-small.jpeg');
  }

  #no-cover {
    display: none;
  }

  .label {
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    .time {
      flex-grow: 1;
    }
    .volume-button {
      cursor: pointer;
      user-select: none;
      outline: none !important;
    }
  }

  @media (max-width: 500px) {
    .bandcamp-big-embeds {
      margin: 0 5px;
    }
    .song-cover {
      display: none;
    }
    .others {
      margin-top: 0;
    }
  }

  #powered-by-soundcloud-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .icon {
    width: 45px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    top: 50%;
    transform: translate(-11%, -50%);
  }
  #pause-icon {background-image: url('../assets/icons/iconmonstr-media-control-49-48.png')}
  #play-icon {
    left: 3px;
    background-image: url('../assets/icons/iconmonstr-media-control-48-48.png');
  }
</style>
