<template>
  <div id="gallery-component" class="gallery">
    <!-- Full-width images with number text -->
    <div class="mySlides">
      <div class="numbertext">
        <i class="im im-photo-camera"></i>
        <span class="photo-progress">{{activePhoto}} / {{photos.length}}</span>
      </div>

      <div :id="`${keyName}-gallery-big-photo`" class="big-photo" :class="`${keyName}-${activePhoto}`" :style="{ height: bigWidth + 'px' }"></div>
      <!-- Next and previous buttons -->
      <div class="buttons-container" :style="{ top: (bigWidth/2)*0.85 + 'px' }">
        <div class="prev" v-on:click="updateActivePhoto(-1)">
          <i class="im im-angle-left"></i>
        </div>
        <div class="next" v-on:click="updateActivePhoto(1)">
          <i class="im im-angle-right"></i>
        </div>
      </div>
      <!-- Thumbnail images -->
      <div class="row" v-for="row in rows" :key="row.id" :id="row.id">
        <div class="column"
             v-for="photo in row.photos"
             :key="photo"
             :class="{ active: photo === activePhoto }"
             v-on:click="setActivePhoto(photo)">
          <div class="small-photo demo cursor" :class="`${keyName}-${photo}`" :style="{ height: littleWidth + 'px' }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GallerySlides',
  props: {
    keyName: String,
    total: Number
  },
  data: (instance) => {
    const photos = Array.from({length: instance.total}, (v, i) => i).map(p => (p + 1));
    const rowCount =  Math.ceil(photos.length / 5);
    // this is also super hacky, but seems consistent enough!
    const rows = Array
        .from({length: rowCount}, (v, i) => i)
        .map((r, i) => {
          // get the base id
          const base = (r * 5) + 1;
          // figure out how many are left over
          const length = Math.min(photos.length - base + 1, 5);
          // make the list of arrays
          return {
            id: `${instance.keyName}-row-${i}`,
            photos: Array.from({length}, (v, i) => i).map(p => (base + p))
          }
        });
    return {
      activePhoto: 1,
      photos,
      rows,
      bigWidth: 600,
      littleWidth: 120,
    }
  },
  created() {
    window.addEventListener("resize", this.changeWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.changeWidth);
  },
  mounted() {
    this.changeWidth();
  },
  methods: {
    changeWidth() {
      if (this.bigWidth) {
        this.bigWidth = document.getElementById(`${this.keyName}-gallery-big-photo`).offsetWidth * 0.75;
        this.littleWidth = document.getElementById(`${this.keyName}-row-0`).offsetWidth / this.photos.length * 0.666666667 * this.rows.length;
      }
    },
    updateActivePhoto(delta) {
      if (this.activePhoto + delta < 1 || this.activePhoto + delta > this.photos.length) {
        return;
      }
      this.activePhoto += delta;
    },
    setActivePhoto(id) {
      this.activePhoto = id;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }

  /* Position the image container (needed to position the left and right arrows) */
  .gallery {
    position: relative;
  }

  /* Hide the images by default */
  .mySlides {
    // display: none;
    position: relative;
  }

  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
    cursor: pointer;
  }



  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    //top: calc(50% - 100px);
    //margin-top: -50px;
    text-shadow: 1px 1px 1px black, -1px 1px 1px black, -1px -1px 1px black, 1px -1px 1px black;
    width: auto;
    padding: 2%;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
    .im {
      position: relative;
      top: 4px;
    }
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    //text-shadow: 0px 0px 15px 5px #000000;
    text-shadow: 1px 1px 1px black, -1px 1px 1px black, -1px -1px 1px black, 1px -1px 1px black;
    font-size: 18px;
    position: absolute;
    top: 2%;
    left: 2%;
    display: flex;
    align-items: center;
    font-weight: 600;
    .photo-progress {
      margin-left: 10px;
    }
  }

  /* Container for image text */
  .caption-container {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
  }

  .row {
    // border-top: 5px solid rgba(0, 0, 0, .80);
    margin-top: 5px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  /* Add a transparency effect for thumbnail images */
  .demo {
    opacity: 0.6;
    &.active,
    &:hover {
      opacity: 1;
    }
  }

  /* Six columns side by side */
  .column {
    float: left;
    width: calc(20% - 2px);
    &.active {
      .demo {
        opacity: 1;
      }
    }
    margin-right: 2px;
    &:last-child {
      width: 20%;
      margin-right: 0;
    }
  }

  .big-photo {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: #f9fafa;
  }

  .small-photo {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: #f9fafa;
    width: 100%;
  }

  .buttons-container {
    position: absolute;
    width: 100%;
  }

  @media (max-width: 600px) {
    .photo-progress {
      display: none;
    }
    .numbertext {
      font-size: 10px;
    }
  }

  // just image ids, really hacky what whatevs :D
  .mint-1 {
    background-image: url('../../assets/Mint-Poster.png');
    background-size: contain;
  }
  .mint-2 {
    background-image: url('../../assets/mint/9.jpg');
  }
  .mint-3 {
    background-image: url('../../assets/mint/2.jpg');
  }
  .mint-4 {
    background-image: url('../../assets/mint/7.jpg');
  }
  .mint-5 {
    background-image: url('../../assets/mint/5.jpg');
  }
  .mint-6 {
    background-image: url('../../assets/mint/6.jpg');
  }
  .mint-7 {
    background-image: url('../../assets/mint/4.jpg');
  }
  .mint-8 {
    background-image: url('../../assets/mint/8.jpg');
  }
  .mint-9 {
    background-image: url('../../assets/mint/3.jpg');
  }
  .mint-10 {
    background-image: url('../../assets/mint/10.jpg');
  }
  .mint-11 {
    background-image: url('../../assets/mint/1.jpg');
  }

  .harvard-and-stone-1 {
    background-image: url('../../assets/superok-h&s.jpg');
    background-size: contain;
  }
  .harvard-and-stone-2 {
    background-image: url('../../assets/harvard-and-stone/h&s5.jpeg');
  }
  .harvard-and-stone-3 {
    background-image: url('../../assets/harvard-and-stone/h&s2.jpeg');
  }
  .harvard-and-stone-4 {
    background-image: url('../../assets/harvard-and-stone/h&s3.jpeg');
  }
  .harvard-and-stone-5 {
    background-image: url('../../assets/mint/7.jpg');
  }

  .barrel-tavern-1 {
    background-image: url('../../assets/barrel-tavern-7.30.jpeg');
    background-size: contain;
  }
  .barrel-tavern-2 {
    background-image: url('../../assets/barrel-tavern/1.jpg');
  }
  .barrel-tavern-3 {
    background-image: url('../../assets/barrel-tavern/2.jpg');
  }
  .barrel-tavern-4 {
    background-image: url('../../assets/barrel-tavern/3.jpg');
  }
  .barrel-tavern-5 {
    background-image: url('../../assets/barrel-tavern/4.jpg');
  }
  .barrel-tavern-6 {
    background-image: url('../../assets/barrel-tavern/5.jpg');
  }
  .barrel-tavern-7 {
    background-image: url('../../assets/barrel-tavern/6.jpg');
  }
  .barrel-tavern-8 {
    background-image: url('../../assets/barrel-tavern/7.jpg');
  }
  .barrel-tavern-9 {
    background-image: url('../../assets/barrel-tavern/8.jpg');
  }
  .barrel-tavern-10 {
    background-image: url('../../assets/barrel-tavern/9.jpg');
  }

  .molly-malones-1 {
    background-image: url('../../assets/SuperOK-MollyMalones-8.20-11x17.png');
    background-size: contain;
  }
  .molly-malones-2 {
    background-image: url('../../assets/molly-malones/1.jpg');
  }
  .molly-malones-3 {
    background-image: url('../../assets/molly-malones/2.jpg');
  }
  .molly-malones-4 {
    background-image: url('../../assets/molly-malones/3.jpg');
    background-size: contain;
  }
  .molly-malones-5 {
    background-image: url('../../assets/molly-malones/4.jpg');
    background-size: contain;
  }
  .molly-malones-6 {
    background-image: url('../../assets/molly-malones/5.jpg');
    background-size: contain;
  }
  .molly-malones-7 {
    background-image: url('../../assets/molly-malones/6.jpg');
    background-size: contain;
  }
  .molly-malones-8 {
    background-image: url('../../assets/molly-malones/7.jpg');
    background-size: contain;
  }
  .molly-malones-9 {
    background-image: url('../../assets/molly-malones/8.jpg');
    background-size: contain;
  }
  .molly-malones-10 {
    background-image: url('../../assets/molly-malones/9.jpg');
    background-size: contain;
  }

</style>
