<template>
  <div id="info-component" class="quote-box component with-padding">
    <div id="info-icon" class="info">
      <div class="component-icon info-icon"></div>
      <span class="info-label">About Super OK</span>
    </div>
    <div class="section">
      <p><i><a target="_blank" href="https://dopecausewesaid.com/dope-features/interview-los-angeles-based-rock-band-super-ok-talk-about-their-debut-album-departures">Interview with DOPECAUSEWESAID</a></i></p>
      <p><i><a target="_blank" href="http://voyagela.com/interview/meet-super-ok-west-side/">Interview with Voyage LA Magazine</a></i></p>
      <p>
        <span>From an alternate timeline where the “M” in MTV still means “music” and playlists are still called mixtapes comes Super OK, a four-piece alternative powerhouse from Los Angeles.</span>
        <span>Having been told they kinda sound like Foo Fighters and Muse, Super OK eagerly welcomes any and all comparisons to their influences, including Arctic Monkeys, Stone Temple Pilots, Nirvana, and anything Chris Cornell touched.</span>
        <span>Benjamin Buday and Davey Henry share vocals and guitars, Drew Stutz mans the drums, and Kat Cannon blasts the bass.</span>
      </p>
      <div v-on:click="showAll = true;" class="read-more" v-if="!showAll">
        <u>Show More</u>
      </div>
      <div style="margin-top: 15px;" v-if="showAll">
        <p>
          <span>Davey, Benjamin and Kat formed Super OK in late 2018.</span>
          <span>At the outset of 2020, the then-trio geared up to record an album and start gigging when the pandemic put the band on hold.</span>
          <span>Super OK decided to forge ahead with recording, albeit with a slight twist - they would piece together their songs entirely remotely from their separate home studios.</span>
          <span>Toward the end of recording "Departures," Drew Stutz joined Super OK as the drummer in June 2021. Super OK then released their 13-track debut album, “Departures,” on January 28th, 2022.</span>
        </p>
        <p>
          <span>In the summer of 2024, the band would go on to release four singles: “Annoyed the Void,” “Changes,” “Seeing Illusions,” and “All Along.”</span>
        </p>
        <p>
          <span>Super OK is actively performing in 2024 and has taken the stage at The Mint, The Whisky A Go Go, Molly Malone's, El Cid, The Viper Room, Harvelle's, The Good Nite, Boomtown Brewery, among others.</span>
        </p>
        <div v-on:click="showAll = false;" class="read-more">
          <u>Show Less</u>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data: () => ({
    showAll: false,
  }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .info {
    display: flex;
    align-items: center;
    .info-label {
      margin-left: 10px;
      font-weight: bold;
      font-size: 20px;
    }
    margin-bottom: 10px;
  }
  .section {
    padding: 10px 15px;
    // text-indent: 20px;
  }
  span {
    margin-right: 5px;
  }
  p {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .read-more {
    margin-top: 10px;
    font-style: italic;
    cursor: pointer;
  }
</style>
