<template>
  <div id="app" :class="{ hi: this.$route.path === '/hi' || this.$route.path.includes('/show/') }">
    <div id="banner" ></div>
    <div id="banner-bar" :class="{ sticky: stickyBanner }">
      <div id="inner-banner">
        <div id="logo" @click="scrollToTop()" />
        <div id="social-links">
          <!-- General Links
          <a href="https://open.spotify.com/artist/24Y8uCyQ4xwFDutTtfI47D?si=xXDTNIA7QwyWD4VqFZbFzg" target="_blank"><i
              class="im im-spotify"></i></a>
          <a href="https://www.youtube.com/channel/UCDic5bNX3nL6SVoaNvaowQA" target="_blank"><i
              class="im im-youtube"></i></a>
          <a href="https://music.apple.com/us/artist/super-ok/1551322422" target="_blank"><i class="im im-apple-os"></i></a>
          <a href="https://www.amazon.com/gp/product/B08VGVM4JP" target="_blank"><i class="im im-amazon"></i></a>
          <a href="https://superok.bandcamp.com/" id="bandcamp-container" target="_blank">
            <div id="bandcamp-icon"></div>
          </a>
          -->
          <!-- Departure Links
          <a href="https://open.spotify.com/album/75ijGKYMwGG5CZne26bz9q?si=4SEd8iK6TzeXaEYhR_FBvg" target="_blank"><div class="icon" id="spotify-icon"></div></a>
          <a href="https://www.youtube.com/channel/UCDic5bNX3nL6SVoaNvaowQA" target="_blank"><div class="icon" id="youtube-icon"></div></a>
          <a href="https://music.apple.com/us/album/departures/1601663098?uo=4" target="_blank"><div class="icon" id="apple-icon"></div></a>
          <a href="https://www.amazon.com/dp/B09P1T6MNR/" target="_blank"><div class="icon" id="amazon-icon"></div></a>
          <a href="https://superok.bandcamp.com/" id="bandcamp-container" target="_blank"><div id="bandcamp-icon"></div></a>
          -->
          <!-- Annoyed Links -->
          <a href="https://open.spotify.com/track/1AvFwHote8iJAvm596czhI?si=b27b762b560e4285" target="_blank"><div class="icon" id="spotify-icon"></div></a>
          <a href="https://www.youtube.com/watch?v=sa3-2v0VxFA" target="_blank"><div class="icon" id="youtube-icon"></div></a>
          <a href="https://music.apple.com/us/album/annoyed-the-void-single/1748970091" target="_blank"><div class="icon" id="apple-icon"></div></a>
          <a href="https://amazon.com/music/player/albums/B0D59CV6KR?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_0y1oep7Yb1g624s30ZQb5Z1l4" target="_blank"><div class="icon" id="amazon-icon"></div></a>
          <a href="https://superok.bandcamp.com/" id="bandcamp-container" target="_blank"><div id="bandcamp-icon"></div></a>
          <!-- Social Links -->
          <a href="https://www.instagram.com/wearesuperok/" target="_blank"><div class="icon" id="instagram-icon"></div></a>
          <a href="https://www.facebook.com/wearesuperok" target="_blank"><div class="icon" id="facebook-icon"></div></a>
          <!-- <a href="https://twitter.com/wearesuperok" target="_blank"><i class="im im-twitter"></i></a> -->
        </div>
      </div>
    </div>
    <div id="container" :class="{ 'adjust-to-banner': stickyBanner }">
      <router-view/>
    </div>
    <div id="footer">
      <div class="component with-padding">
        <div class="footer-content">
          <div>Inquiries: <a href="mailto:info@superok.band">info@superok.band</a></div>
          <div v-on:click="scrollTo()" class="back-to-top">
            <span style="margin-right: 10px;">Back to top</span>
            <i class="im im-arrow-up-circle"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './styles/reset.scss';
import './styles/slider.scss';
import router from "@/router";

export default {
  name: 'App',
  created() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener("resize", this.changeWidth);
  },
  mounted() {
    this.stickyHeight = this.getScrollToValue();
  },
  watch:{
    $route (){
      this.stickyHeight = this.getScrollToValue();
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener("resize", this.changeWidth);
  },
  data: () => ({
    stickyBanner: false,
    stickyHeight: 0,
  }),
  methods: {
    changeWidth() {
      this.stickyHeight = this.getScrollToValue();
    },
    onScroll() {
      this.stickyBanner = window.scrollY > this.stickyHeight;
    },
    getScrollToValue() {
      if (this.$route.path === '/hi' || this.$route.path.includes('/show/')) {
        return 0;
      }
      const width = document.body.offsetWidth;
      if (width > 1000) {
        return 495;
      } else if (width > 750) {
        return 395;
      } else if (width > 650) {
        return 295;
      } else if (width > 500) {
        return 255;
      } else {
        return 195;
      }
    },
    scrollToTop() {
      window.scrollTo({top: 0});
      router.push('/');
    },
    scrollTo(section) {
      let eLocation;
      const offset = window.screen.width > 530 ? 100 : 160;
      switch(section) {
        case 'news':
          eLocation = document.getElementById('news-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        case 'quote':
          eLocation = document.getElementById('quote-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        case 'photos':
          eLocation = document.getElementById('gallery-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        case 'music':
          eLocation = document.getElementById('music-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        case 'video':
          eLocation = document.getElementById('video-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        case 'info':
          eLocation = document.getElementById('info-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        default:
          eLocation = 0;
      }
      window.scrollTo({top: eLocation, behavior: 'smooth'});
    },
  }
}
</script>

<style lang="scss">
html {
  background: black;
}

body {
  background: #f9fafa;
}

html,
body,
#app {
  width: 100%;
  overflow-x: hidden;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  &.hi {
    background-image: url('./assets/dep-just-sky.png');
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1300px) {
      background-image: url('./assets/dep-just-sky-m.jpg');
    }

    @media (max-width: 900px) {
      background-image: url('./assets/dep-just-sky-m.jpg');
    }

    @media (max-width: 500px) {
      background-image: url('./assets/dep-just-sky-s.jpg');
    }

    #banner {
      display: none;
    }
  }
}

#banner {
  background-image: url('./assets/the-viper-banner.png');
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

#inner-banner {
  // These four help make the shadow more effective
  position: relative;
  width: calc(100% + 20px);
  left: -10px;
  padding: 0 10px;

  height: 70px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .im {
    color: white;
    padding: 0 8px;
  }
}

#banner-bar {
  overflow: hidden;
  width: 100%;
  padding-bottom: 10px;
  z-index: 100;

  &.sticky {
    position: fixed;
    top: 0;

    #inner-banner {
      box-shadow: 0 9px 8px -6px black;
      // box-shadow: 0 8px 6px -6px black;
    }
  }
}

.component {
  background: white;
  margin-bottom: 35px;
  &.with-padding {
    padding: 25px;
  }
}

.hi .solo-component {
  border-radius: 10px;
}

#social-links {
  display: flex;
}

#bandcamp-container {
  width: 45px;
  height: 25px;
  position: relative;
}

.icon {
  width: 45px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

#bandcamp-icon {
  background-image: url('./assets/bandcamp-button-circle-line-white-128.png');
  width: 45px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
}
#amazon-icon {
  background-image: url('./assets/icons/iconmonstr-amazon-1-48.png');
}
#apple-icon {
  background-image: url('./assets/icons/iconmonstr-apple-os-1-48.png');
}
#facebook-icon {
  background-image: url('./assets/icons/iconmonstr-facebook-6-48.png');
}
#instagram-icon {
  background-image: url('./assets/icons/iconmonstr-instagram-11-48.png');
}
#spotify-icon {
  background-image: url('./assets/icons/iconmonstr-spotify-1-48.png');
}
#youtube-icon {
  background-image: url('./assets/icons/iconmonstr-youtube-6-48.png');
}

#logo {
  font-weight: bolder;
  font-size: 45px;
  cursor: pointer;
}

#container {
  padding: 35px;
  padding-bottom: 0;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.adjust-to-banner {
    margin-top: 80px;
  }
  margin-bottom: 80px;
}

#components {
  width: 100%;
  max-width: 1000px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  .component {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
}

.hi {
  #footer {
    display: none;
  }
}

.footer-content {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-to-top {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

#logo {
  //background-image: url('./assets/SuperOK8_white.png');
  background-image: url('./assets/SuperOKBoom-WhiteBlack.png');
  width: 280px;
  height: 90px;
  //height: 66px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  //top: -2px;
  top: 2px;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-label {
  margin-left: 15px;
  font-weight: bold;
  font-size: 20px;
  color: #2c3e50;
  a {
    &:link, &:visited, &:hover, &:active {
      text-decoration: none;
      color: #2c3e50;
    }
  }
}

@media (max-width: 1000px) {
  #banner {
    height: 400px;
  }
}

@media (max-width: 750px) {
  #banner {
    height: 300px;
  }
}


@media (max-width: 650px) {
  #container {
    padding: 17px;
  }
  #banner {
    height: 250px;
  }
}

@media (max-width: 500px) {
  #banner {
    height: 200px;
  }
}

.hide {
  display: none;
}

@media (max-width: 530px) {
  #inner-banner {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
  #social-links {
    margin-top: 30px;
    position: relative;
    left: -5px;
    a {
      margin-right: 7px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  #container {
    &.adjust-to-banner {
      margin-top: 125px;
    }
  }
}
</style>
