<template>
  <div id="shows-component" class="component with-padding">
    <div id="info-icon" class="info">
      <div class="component-icon calendar-icon"></div>
      <router-link to="/shows">
        <span class="info-label">Shows</span>
      </router-link>
    </div>
    <div class="section">
      <div class="show-container" v-for="show in futureShowList" :key="show.id">
        <FutureShow :show="show" />
      </div>
      <div v-if="futureShowList.length === 0" class="no-future-shows-container">
        <div>No shows planned right now! Check back soon though</div>
      </div>
    </div>
    <div class="section past-shows-container">
      <h2 style="text-align: center; margin-top: 20px;">Past Shows</h2>
      <div class="past-shows-list-container">
        <div class="past-shows-list">
          <div class="row" v-for="(show) in showAll ? pastShowList : pastShowList.filter((_, i) => i < 7)"
               :key="show.id"
               v-on:click="navigateToShow(show.id)">
            <div class="col date-col">{{show.shortDate}}</div>
            <div class="col">{{show.name}}</div>
            <div class="col location-col">{{show.shortLocation}}</div>
          </div>
        </div>
      </div>
      <div v-on:click="showAll = true;" class="read-more" v-if="!showAll">
        <u>Show More</u>
      </div>
    </div>
  </div>
</template>

<script>

import FutureShow from "@/components/FutureShow";
import {futureShowList} from "@/global/futureShowList";
import {pastShowList} from "@/global/pastShowList";
import router from "@/router";

export default {
  name: 'Shows',
  components: {FutureShow},
  data: () => {
    return {
      showAll: false,
      futureShowList,
      // pastShowList: this.showAll ? pastShowList : pastShowList.filter((_, i) => i < 7)
      pastShowList
    }
  },
  methods: {
    navigateToShow(showId) {
      console.log(showId);
      router.push(`/show/${showId}`);
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.info {
  display: flex;
  align-items: center;
  .info-label {
    margin-left: 10px;
    font-weight: bold;
    font-size: 20px;
  }
  margin-bottom: 10px;
}
.section {
  padding: 10px 15px;
}
span {
  margin-right: 5px;
}
p {
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.title {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  .date {
    font-style: oblique;
  }
}
.read-more {
  margin-top: 10px;
  font-style: italic;
  cursor: pointer;
}

.pic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  &.at-bottom {
    margin-bottom: 25px;
  }
}
.img-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  border-radius: 10px;
  -webkit-box-shadow: 0px -1px 16px 5px #828282;
  box-shadow: 0px -1px 16px 5px #828282;
  width: 70%;
}
.clickable {
  cursor: pointer;
}

.centered {
  text-align: center;
}

.performing-with {
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 10px;
}

.details {
  .sub-title {
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 3px;
  }
  .details-text {
    line-height: 32px;
  }
}

.show-container {
  &:first-of-type {
    margin-top: 0;
    border-top: 0;
    padding-top: 0;
  }
  margin-top: 50px;
  border-top: 1px solid;
  padding-top: 50px;
}

.past-shows-container {
  margin-top: 50px;
  border-top: 1px solid;
}

.past-shows-list {
  margin-top: 15px;
  display: table;
  width: 100%;
  .row {
    display: table-row;
    cursor: pointer;
    font-weight: 500;
  }
  .col {
    display: table-cell;
    padding: 10px 0;
  }
}

.read-more {
  text-align: center;
}

.past-shows-list-container {
  width: 100%;
  max-width: 700px;
  position: relative;
  margin: 0 auto;
}

.no-future-shows-container {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -15px;
}

@media (max-width: 700px) {
  .past-shows-list .location-col {
    display: none;
  }
  .section {
    padding: 10px 0;
  }
}

@media (max-width: 290px) {
  .past-shows-list .date-col {
    display: none;
  }
}

</style>
