<template>
  <div id="gallery-component" class="gallery component">
    <!-- Full-width images with number text -->
    <div class="mySlides">
      <div class="numbertext">
        <div class="component-icon camera-icon-white"></div>
        <span class="photo-progress">{{activePhoto}} / {{photos.length}}</span>
      </div>
      <div id="gallery-big-photo" class="big-photo" :class="`p${activePhoto}`" :style="{ height: bigWidth + 'px' }"></div>
    </div>
    <!-- Next and previous buttons -->
    <div class="prev" v-on:click="updateActivePhoto(-1)">
      <i class="im im-angle-left"></i>
    </div>
    <div class="next" v-on:click="updateActivePhoto(1)">
      <i class="im im-angle-right"></i>
    </div>
    <!-- Thumbnail images -->
    <div id="thumbnail-row" class="row">
      <div class="column"
           v-for="photo in photos"
           :key="photo"
           :class="{ active: photo === `p${activePhoto}` }"
           v-on:click="setActivePhoto(photo)"
      >
        <div class="small-photo demo cursor" :class="photo" :style="{ height: littleWidth + 'px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Gallery',
  data: () => ({
    activePhoto: 1,
    photos: [
      'p1',
      'p2',
      'p3',
      'p4',
      'p5'
    ],
    bigWidth: 600,
    littleWidth: 130,
  }),
  created() {
    window.addEventListener("resize", this.changeWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.changeWidth);
  },
  mounted() {
    this.changeWidth();
  },
  methods: {
    changeWidth() {
      if (this.bigWidth) {
        this.bigWidth = document.getElementById("gallery-big-photo").offsetWidth * 0.666666667;
        this.littleWidth = document.getElementById("thumbnail-row").offsetWidth / this.photos.length * 0.666666667;
      }
    },
    updateActivePhoto(delta) {
      if (this.activePhoto + delta < 1 || this.activePhoto + delta > this.photos.length) {
        return;
      }
      this.activePhoto += delta;
    },
    setActivePhoto(id) {
      this.activePhoto = Number(id[1]);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }

  /* Position the image container (needed to position the left and right arrows) */
  .gallery {
    position: relative;
  }

  /* Hide the images by default */
  .mySlides {
    // display: none;
  }

  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
    cursor: pointer;
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 40px);
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 18px;
    position: absolute;
    top: 25px;
    left: 25px;
    display: flex;
    align-items: center;
    .photo-progress {
      margin-left: 10px;
    }
  }

  /* Container for image text */
  .caption-container {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
  }

  .row {
    // border-top: 5px solid rgba(0, 0, 0, .80);
    margin-top: 5px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  /* Add a transparency effect for thumbnail images */
  .demo {
    opacity: 0.6;
    &.active,
    &:hover {
      opacity: 1;
    }
  }

  /* Six columns side by side */
  .column {
    float: left;
    width: calc(20% - 2px);
    &.active {
      .demo {
        opacity: 1;
      }
    }
    margin-right: 2px;
    &:last-child {
      width: 20%;
      margin-right: 0;
    }
  }

  .big-photo {
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: rgba(0, 0, 0, .80);
  }

  .small-photo {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: rgba(0, 0, 0, .25);
    width: 100%;
  }

  .p1 {
    background-image: url('../assets/new-photos/SUPEROK_2.jpg');
  }
  .p2 {
    background-image: url('../assets/new-photos/SUPEROK_8.jpg');
  }
  .p3 {
    background-image: url('../assets/new-photos/SUPEROK_10.jpeg');
  }
  .p4 {
    background-image: url('../assets/new-photos/SUPEROK_4.jpg');
  }
  .p5 {
    background-image: url('../assets/new-photos/SUPEROK_7.jpg');
  }

</style>
