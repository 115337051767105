<template>
  <div id="quote-component" class="quote-box component with-padding">
    <div class="big-quote-mark"><div class="component-icon quote-icon"></div></div>
    <div class="quote">
      <span>Hitting play on the new track [All Along], Super OK kicks off the listening experience with high-energy
        alternative rock instrumentals that instantly take the vibes sky-high.</span>
      <span>They waste no time diving into the exhilarating yet nerve-wracking experience of realizing you’ve found something (or someone) truly special.</span>
    </div>
    <div class="source">
      <a target="_blank" class="source"
         href="https://www.buzz-music.com/post/feel-the-love-with-super-ok-s-new-single-all-along">-Buzz Music LA</a>
    </div>
    <div class="big-quote-mark"><div class="component-icon quote-icon"></div></div>
    <div class="quote">
      <span>Somewhere between Social D and Hüsker Du, Super OK deliver a powerful punch: a dense, rockin' sound, a lo-fi sound mix […] the tone and attack are just right.</span>
      <span>Oh, and did we mention catchy tunes?</span>
      <span>At the core of its visceral vibe, the band has a knack for simple chord changes that hook the listener.</span>
    </div>
    <div class="source">
      <a target="_blank" class="source" href="https://www.instagram.com/p/CfXB4EfPOoI/">-Music Connection</a>
    </div>
    <div class="big-quote-mark"><div class="component-icon quote-icon"></div></div>
    <div class="quote">
      <span>Super Ok has great chemistry as a band and Departures has got a solid sound, with some real stand out gems, too.</span>
    </div>
    <div class="source">
      <a target="_blank" class="source" href="https://www.divideandconquermusic.com/indie-music-album-reviews/super-ok-departures">-Divide and Conquer</a>
    </div>
    <div class="big-quote-mark"><div class="component-icon quote-icon"></div></div>
    <div class="quote">
      <span>Psychedelic rock, ambient, and grunge merge to form Super OK’s intensely felt debut album, “Departures”.</span>
      <span>Volume is a given for the sound holds absolutely nothing back.</span>
      <span>A degree of decadence runs through the entirety of the journey for this is a rich, carefully considered sound.</span>
      <span>From the vocals that swim through the din to the way that they let their amps simply sing everything here has an intrinsic beauty to it one that pops with so much color.</span>
      <span>Lyrics too feature an uncanny knack for storytelling with the evolution of the sound becoming at times life-affirming.</span>
    </div>
    <div class="source">
      <a target="_blank" class="source" href="https://dopecausewesaid.com/dope-features/review-departures-album-by-super-ok">-DOPECAUSEWESAID</a>
    </div>
    <div class="big-quote-mark"><div class="component-icon quote-icon"></div></div>
    <div class="quote">[616] - A stellar song here from Super OK. It has a bit of a Foo Fighters sound, with a grungier twist.</div>
    <div class="source">
      <a target="_blank" class="source" href="https://fromthestrait.com/articles/8-tracks-collection-cxiv/">-From the Strait</a>
    </div>
    <div class="big-quote-mark"><div class="component-icon quote-icon"></div></div>
    <div class="quote">Super OK Impresses Audience with Reminiscent Grit In "616"</div>
    <div class="source">
      <a target="_blank" class="source" href="https://www.buzz-music.com/post/super-ok-impresses-audience-with-reminiscent-grit-in-616">-Buzz Music LA</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Quote',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .quote-box {
    .big-quote-mark {
      display: inline;
      text-align: left;
    }

    .quote {
      margin: 10px 15px 5px;
      font-size: 23px;
      font-weight: 400;
      span {
        margin-left: 5px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    .source {
      text-align: right;
      font-style: italic;
    }
  }
</style>
