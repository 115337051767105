<template>
  <div class="show centered">
    <p v-if="isIndividual" class="title">
      <span class="date" v-html="show.date" />
      <span>&nbsp;&#8211;</span>
      <span v-html="show.name" />
    </p>
    <p v-if="!isIndividual" class="title info-label">
      <router-link :to='`/show/${show.id}`'>
        <span class="date" v-html="show.date" />
        <span>&nbsp;&#8211;</span>
        <span v-html="show.name" />
      </router-link>
    </p>
    <div v-if="show.imgSrc" class="pic-container at-bottom">
      <a :href="show.link" target="_blank">
        <img class="img" :src="getImgUrl(show.imgSrc)">
      </a>
    </div>
    <div class="performing-with">
      <span v-html="show.liveAt" />
    </div>
    <div v-if="show.performingWith">
      <span>Performing with</span>
      <span v-html="show.performingWith" />
    </div>
    <div class="details">
      <div class="details-text">
        <span v-for="(deet, i) in show.venueDetails" :key="`${show.id}-venue-deet-${i}`">
          <span v-html="deet" />
          <span v-if="show.venueDetails.length - 1 > i">&nbsp;&#8211;</span>
        </span>
      </div>
      <div class="details-text">
        <span v-for="(deet, i) in show.timeDetails" :key="`${show.id}-venue-deet-${i}`">
          <span v-html="deet" />
          <span v-if="show.timeDetails.length - 1 > i">&nbsp;&#8211;</span>
        </span>
      </div>
      <div class="details-text">
        <span v-html="show.address" />
        <span><a target="_blank" :href="show.addressLink"><i>(Open in Google Maps)</i></a></span>
      </div>
      <div v-if="show.googleCalLink">
        <a :href="show.googleCalLink" target="_blank">Save on Google Calendar</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FutureShow',
  props: {
    show: Object,
    isIndividual: Boolean
  },
  methods: {
    getImgUrl(pic) {
      return require('../assets/'+pic)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.info {
  display: flex;
  align-items: center;
  .info-label {
    margin-left: 15px;
    font-weight: bold;
    font-size: 20px;
  }
  margin-bottom: 10px;
}
.section {
  padding: 10px 15px;
  // text-indent: 20px;f
}
span {
  margin-right: 5px;
}
p {
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.title {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  .date {
    font-style: oblique;
  }
}
.read-more {
  margin-top: 10px;
  font-style: italic;
  cursor: pointer;
}

.pic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  &.at-bottom {
    margin-bottom: 25px;
  }
}
.img-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  border-radius: 10px;
  -webkit-box-shadow: 0px -1px 16px 5px #828282;
  box-shadow: 0px -1px 16px 5px #828282;
  width: 95%;
  max-width: 650px;
  max-height: 650px;
}
.clickable {
  cursor: pointer;
}

.centered {
  text-align: center;
}

.performing-with {
  //font-size: 19px;
  font-weight: 500;
  margin-bottom: 10px;
}

.details {
  .sub-title {
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 3px;
  }
  .details-text {
    line-height: 32px;
  }
}
</style>
