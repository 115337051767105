<template>
  <div id="video-component" class="component with-padding">
    <div>
      <div class="component-icon video-icon"></div>
    </div>
    <div class='embed-container'>
      <iframe src='https://www.youtube.com/embed/tGf-F-sF7BQ?si=UJ6U2fwH7HDQccAB' frameborder='0' allowfullscreen></iframe>
    </div>
    <!--
    <div class='embed-container'>
      <iframe src='https://www.youtube.com/embed/iijzFXXTs_0' frameborder='0' allowfullscreen></iframe>
    </div>
    <div class='embed-container'>
      <iframe src='https://www.youtube.com/embed/XX4Yh4VU028' frameborder='0' allowfullscreen></iframe>
    </div>
    <div class='embed-container' v-if="showAll">
      <iframe src='https://www.youtube.com/embed/twHz3BA5fDU' frameborder='0' allowfullscreen></iframe>
    </div>
    -->
  </div>
</template>

<script>
export default {
  name: 'Video',
  data: () => ({
    showAll: false,
    videoWidth: 600,
    videoHeight: 450,
  }),
  created() {
    window.addEventListener("resize", this.changeWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.changeWidth);
  },
  mounted() {
    this.changeWidth();
  },
  methods: {
    changeWidth() {
      if (this.videoWidth) {
        const windowWidth = window.screen.width;
        const componentWidth = document.getElementById("video-component").offsetWidth;
        if (windowWidth > componentWidth) {
          this.videoWidth = componentWidth * 0.8;
        } else {
          this.videoWidth = windowWidth * 0.85;
        }
        this.videoHeight = this.videoWidth * 0.5625;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 15px;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
