<template>
  <div id="components">
    <div class="nav">
      <div id="nav-buttons">
        <div v-on:click="scrollTo('news')" class="nav-button-container"><div class="nav-button star-icon"></div></div>
        <div v-on:click="scrollTo('music')" class="nav-button-container"><div class="nav-button headphones-icon"></div></div>
        <div v-on:click="scrollTo('video')" class="nav-button-container"><div class="nav-button video-icon"></div></div>
        <div v-on:click="scrollTo('shows')" class="nav-button-container"><div class="nav-button calendar-icon"></div></div>
        <div v-on:click="scrollTo('quote')" class="nav-button-container"><div class="nav-button quote-icon"></div></div>
        <div v-on:click="scrollTo('photos')" class="nav-button-container"><div class="nav-button camera-icon"></div></div>
        <div v-on:click="scrollTo('info')" class="nav-button-container"><div class="nav-button info-icon"></div></div>
      </div>
    </div>
    <News :navigateToMusic="scrollToMusic" />
    <Music :musicSrc="'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1385972968%3Fsecret_token%3Ds-gMG5vn7O9jk&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'" />
    <Video />
    <Shows />
    <Quote />
    <Gallery />
    <About />
  </div>
</template>

<script>

import Shows from "@/components/Shows";
import News from "@/components/News";
import Quote from "@/components/Quote";
import Gallery from "@/components/Gallery";
import Music from "@/components/Music";
import Video from "@/components/Video";
import About from "@/components/About";

export default {
  name: 'Home',
  components: {Shows, News, About, Video, Music, Gallery, Quote},
  methods: {
    scrollTo(section) {
      let eLocation;
      const offset = window.screen.width > 530 ? 100 : 160;
      switch(section) {
        case 'shows': {
          eLocation = document.getElementById('shows-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        }
        case 'news': {
          eLocation = document.getElementById('news-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        }
        case 'quote': {
          eLocation = document.getElementById('quote-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        }
        case 'photos': {
          eLocation = document.getElementById('gallery-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        }
        case 'music': {
          eLocation = document.getElementById('music-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        }
        case 'video': {
          eLocation = document.getElementById('video-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        }
        case 'info': {
          eLocation = document.getElementById('info-component').getBoundingClientRect().top + window.pageYOffset - offset;
          break;
        }
        default: {
          eLocation = 0;
        }
      }
      window.scrollTo({top: eLocation, behavior: 'smooth'});
    },
    scrollToMusic() {
      this.scrollTo('music');
    }
  }
}

</script>

<style lang="scss">

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
}

#nav-buttons {
  display: flex;
  text-align: center;
  .nav-button-container {
    border-right: 1px solid #2c3e50;
    cursor: pointer;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
      border-right: 0;
    }
  }
  .nav-button {
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 5px;
    background-position: 50%;
    margin: 0 5px;
  }
}

.component-icon {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 50%;
}

.calendar-icon {background-image: url('../assets/icons/iconmonstr-calendar-8-48.png')}
.star-icon {background-image: url('../assets/icons/iconmonstr-star-5-48.png')}
.headphones-icon {background-image: url('../assets/icons/iconmonstr-headphones-16-48.png')}
.video-icon {background-image: url('../assets/icons/iconmonstr-video-1-48.png')}
.quote-icon {background-image: url('../assets/icons/iconmonstr-quote-5-48.png')}
.camera-icon {background-image: url('../assets/icons/iconmonstr-photo-camera-11-48 (1).png')}
.camera-icon-white {background-image: url('../assets/icons/iconmonstr-photo-camera-11-48.png')}
.info-icon {background-image: url('../assets/icons/iconmonstr-info-8-48.png')}
</style>
