<template>
  <div id="news-component" class="quote-box component with-padding">
    <div id="info-icon" class="info">
      <div class="component-icon star-icon"></div>
      <router-link to="/news"><span class="info-label">Super OK News</span></router-link>
    </div>
    <div class="section">

      <div class="news">
        <p class="news-date">9/6/2024 <i style="font-size: 14px;" class="im im-pin"></i></p>
        <div class="news-content">
          <p>
            <span>Our newest single,</span>
            <b>"Seeing Illusions"</b>
            <span>, is available on all platforms! </span>
            <span class="fake-link" v-on:click="$props.navigateToMusic()">Listen now!</span>
          </p>
          <div class="pic-container" style="margin-bottom: 30px;">
            <p></p>
            <a class="img-link" href="https://open.spotify.com/track/712Dbu9O12oiKE0Cxp5fyC?si=bc4263d88c7a4460" target="_blank">
              <img target="_blank" href="https://open.spotify.com/track/712Dbu9O12oiKE0Cxp5fyC?si=bc4263d88c7a4460" class="img"
                   src="../assets/SeeingIllusions.jpg">
            </a>
          </div>
          <p>
            <span>Produced by
              <a href="https://www.instagram.com/deerpilot/" target="_blank">Bobby Danzi</a></span><br>
            <span>Mastered by
              <a href="https://www.instagram.com/wolfgangcasanova/" target="_blank">Ben Ross</a></span><br>
            <span>Cover art by <a href="https://www.instagram.com/gnarccotics/" target="_blank">Walker Dubois</a></span><br>
          </p>
        </div>
      </div>

      <div class="news" v-if="showAll">
        <p class="news-date">09/05/2024</p>
        <div class="news-content">
          <p>
            <span>Our single, All Along, was featured in an article in Buzz Music LA!</span>
            <a target="_blank" href="http://voyagela.com/interview/meet-super-ok-west-side/">Check out the interview here</a>
          </p>
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://www.buzz-music.com/post/feel-the-love-with-super-ok-s-new-single-all-along">
              <img target="_blank" href="https://www.buzz-music.com/post/feel-the-love-with-super-ok-s-new-single-all-along" class="img"
                   src="../assets/BuzzMusic-AllAlong.jpg" alt="Buzz Music LA - All Along">
            </a>
          </div>
        </div>
      </div>

      <div class="news">
        <p class="news-date">8/22/2024 <i style="font-size: 14px;" class="im im-pin"></i></p>
        <div class="news-content">
          <p>
            <span>Our newest single,</span>
            <b>"All Along"</b>
            <span>, is available on all platforms! </span>
            <span class="fake-link" v-on:click="$props.navigateToMusic()">Listen now!</span>
          </p>
          <div class="pic-container" style="margin-bottom: 30px;">
            <p></p>
            <a class="img-link" href="https://open.spotify.com/album/4t4ZdgzAU4P1pCQahhpjac" target="_blank">
              <img target="_blank" href="https://open.spotify.com/album/4t4ZdgzAU4P1pCQahhpjac" class="img"
                   src="../assets/AllAlong-Cover.jpg">
            </a>
          </div>
          <p>
            <span>Produced by
              <a href="https://www.instagram.com/vudaje.official/" target="_blank">Vudajé</a></span><br>
            <span>Mastered by
              <a href="https://www.instagram.com/wolfgangcasanova/" target="_blank">Ben Ross</a></span><br>
          </p>
        </div>
      </div>

      <div class="news">
        <p class="news-date">7/26/2024 <i style="font-size: 14px;" class="im im-pin"></i></p>
        <div class="news-content">
          <p>
            <span>Our newest single,</span>
            <b>"Changes"</b>
            <span>, is available on all platforms! </span>
            <span class="fake-link" v-on:click="$props.navigateToMusic()">Listen now!</span>
          </p>
          <div class="pic-container" style="margin-bottom: 30px;">
            <p></p>
            <a class="img-link" href="https://open.spotify.com/album/4GX9i0ONBPzwO2Mfkeg2fo" target="_blank">
              <img target="_blank" href="https://open.spotify.com/album/4GX9i0ONBPzwO2Mfkeg2fo" class="img"
                   src="../assets/changes.png">
            </a>
          </div>
          <p>
            <span>Produced by
              <a href="https://www.instagram.com/deerpilot/" target="_blank">Bobby Danzi</a></span><br>
            <span>Mastered by
              <a href="https://www.instagram.com/wolfgangcasanova/" target="_blank">Ben Ross</a></span><br>
            <span>Cover art by <a href="https://www.instagram.com/gnarccotics/" target="_blank">Walker Dubois</a></span><br>
          </p>
        </div>
      </div>

      <div class="news">
        <p class="news-date">6/29/2024 <i style="font-size: 14px;" class="im im-pin"></i></p>
        <div class="news-content">
          <p>
            <span>Our newest single,</span>
            <b>"Annoyed the Void"</b>
            <span>, is available on all platforms! </span>
            <span class="fake-link" v-on:click="$props.navigateToMusic()">Listen now!</span>
          </p>
          <div class="pic-container" style="margin-bottom: 30px;">
            <p></p>
            <a class="img-link" href="https://open.spotify.com/track/1AvFwHote8iJAvm596czhI?si=ae7f45ac11604735" target="_blank">
              <img target="_blank" href="https://open.spotify.com/track/1AvFwHote8iJAvm596czhI?si=ae7f45ac11604735" class="img"
                   src="../assets/Annoyed_Option_7.jpg">
            </a>
          </div>
          <p>
            <span>Produced by
              <a href="https://www.instagram.com/deerpilot/" target="_blank">Bobby Danzi</a></span><br>
            <span>Mastered by
              <a href="https://www.instagram.com/wolfgangcasanova/" target="_blank">Ben Ross</a></span><br>
            <span>Cover art by <a href="https://www.instagram.com/gnarccotics/" target="_blank">Walker Dubois</a></span><br>
          </p>
        </div>
      </div>

      <div class="news" v-if="showAll">
        <p class="news-date">10/13/2023 <i style="font-size: 14px;" class="im im-pin"></i></p>
        <div class="news-content">
          <p>
            <span>Our first music video tells the tale of how Super OK ruins a neighbor's date night</span>
            <span>The neighbor is kind of a wad though... maybe he deserved it.</span>
          </p>
          <div class="video-container" id="departures-first-anniversary-video">
            <iframe :height="videoHeight" class="video youtube-video" src='https://www.youtube.com/embed/tGf-F-sF7BQ?si=UJ6U2fwH7HDQccAB' frameborder='0' allowfullscreen></iframe>
          </div>
          <p>
            <span>Directed by <a href="https://www.instagram.com/jmtfilms/" target="_blank">JohnMark Triplett</a></span><br>
            <span>Cinematography by <a href="https://www.instagram.com/jackimoo.dp/" target="_blank">Jacki Moonves</a></span><br>
            <span>Produced by <a href="https://www.instagram.com/super_bentendo_64/" target="_blank">Benjamin Buday</a>, <a href="https://www.instagram.com/teaspoontornado/" target="_blank">Kat Cannon</a> and <a href="https://www.instagram.com/jmtfilms/" target="_blank">JohnMark Triplett</a></span><br>
          </p>
        </div>
      </div>

      <div class="news" v-if="showAll">
        <p class="news-date">01/28/2023 <i style="font-size: 14px;" class="im im-pin"></i></p>
        <div class="news-content">
          <p>
            <span>Happy birthday, Departures!</span>
            <span>We released our debut LP one year ago today.</span>
            <span>To commemorate the occasion, we caught up with <a href="https://www.instagram.com/chrisharvey86/" target="_blank">Chris Harvey</a>, who engineered and produced the album, and we recorded it for your entertainment!</span>
            <span>You may even learn a thing or two about us.</span>
          </p>
          <div class="video-container" id="departures-first-anniversary-video">
            <iframe :height="videoHeight" class="video youtube-video" src='https://www.youtube.com/embed/XnIXMJMxFcU' frameborder='0' allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div class="news" v-if="false">
        <p class="news-date">07/15/2022</p>
        <div class="news-content">
          <p>
            <span>SHOW ALERT!!</span>
          </p>
          <p>
            <span>Check out Super OK at <a target="_blank" href="https://www.thebarreltavern.com/">Barrel Tavern</a> on <i><b>7/30</b></i> with</span>
            <span><a target="_blank" href="https://www.instagram.com/kawaguchi_band/">Kawaguchi</a></span>
          </p>
          <p>
            <span><b>Details:</b></span>
            <span>Free <i>21+</i> show, starts at <i>9pm</i></span>
          </p>
          <p>
            <span><b>Address:</b></span>
            <span>4547 Van Nuys Blvd Sherman Oaks, CA 91403</span>
            <span><a href="https://goo.gl/maps/oRWx2uaHNGFDDHEM7"><i>(Open in Google Maps)</i></a></span>
          </p>
          <div class="pic-container">
            <p></p>
            <a class="img-link" href="https://www.instagram.com/p/CggUcBguEHS/" target="_blank">
              <img target="_blank" href="https://www.instagram.com/p/CggUcBguEHS/" class="img" src="../assets/barrel-tavern-7.30.jpeg">
            </a>
          </div>
        </div>
      </div>

      <div class="news" v-if="false">
        <p class="news-date">07/09/2022</p>
        <div class="news-content">
          <p>
            <span>SHOW ALERT!!</span>
          </p>
          <p>
            <span>Check out Super OK at <a target="_blank" href="https://www.instagram.com/harvardandstone/">Harvard & Stone</a> on <i><b>7/19</b></i> with</span>
            <span><a target="_blank" href="https://www.instagram.com/deerpilot/">Deer Pilot</a></span>
            <span>and</span>
            <span><a target="_blank" href="https://www.instagram.com/kawaguchi_band/">Kawaguchi</a></span>
          </p>
          <div class="pic-container">
            <p></p>
            <a class="img-link" target="_blank" href="https://www.instagram.com/p/Cf-ezWZLXn4/">
              <img target="_blank" href="https://www.instagram.com/p/Cf-ezWZLXn4/" class="img" src="../assets/superok-h&s.jpg">
            </a>
          </div>
        </div>
      </div>

      <div class="news" v-if="showAll">
        <p class="news-date">01/28/2022 <i style="font-size: 14px;" class="im im-pin"></i></p>
        <div class="news-content">
          <p>
            <span>It’s finally time!</span>
            <span>We are proud to announce our debut album, <b><i>Departures</i></b>, has dropped!!</span>
          </p>
          <p>
            <span class="fake-link" v-on:click="$props.navigateToMusic()">Listen here</span>
            <span>or check it out on all platforms!</span>
          </p>
          <div class="pic-container">
            <a class="img-link" target="_blank">
              <img class="img clickable" v-on:click="$props.navigateToMusic()" src="../assets/Departures-Front-2-small.jpeg">
            </a>
          </div>
          <p style="margin-top: 20px;">Produced by <a href="https://www.instagram.com/chrisharvey86/" target="_blank">Chris Harvey</a> and mastered by <a href="https://www.instagram.com/therosswilson/" target="_blank">Ross Wilson</a>.</p>
        </div>
      </div>

      <div class="news" v-if="false">
        <p class="news-date">06/18/2022</p>
        <div class="news-content">
          <p>
            <span>What an amazing time we had at <a href="https://themintla.com/">The Mint</a>!</span>
            <span>We want to first of all thank everyone who came!</span>
            <span>We had such a great time playing for you and look forward to playing for you again.</span>
          </p>
          <p>
            <span>We also want to thank The Mint for having us, Beth and Scott for working with us on booking the show, Joshua for the fantastic sound work, and Elise Carver, <a target="_blank" href="https://www.instagram.com/eaglerockgospel/">The Eagle Rock Gospel Singers</a>, <a
                href="https://www.instagram.com/itsjillianann/" target="_blank">Jillian Ann</a>, and <a href="https://www.instagram.com/the.camillerose/" target="_blank">Camille Rose</a> for putting on wonderful performances!</span>
          </p>
          <p>
            <GallerySlides :keyName="'mint'" :total="10" />
          </p>
        </div>
      </div>

      <div class="news" v-if="false">
        <p class="news-date">06/03/2022</p>
        <div class="news-content">
          <p>
            <span><b>SHOW ALERT!</b></span>
          </p>
          <p>
            <span>Excited to announce our first show of the Summer on Saturday June 18th at <a href="https://themintla.com/">The Mint</a>!</span>
          </p>
          <p>
            <span><b>Details:</b></span>
            <span><i>21+</i> show, doors at <i>7:30</i>, <i>$10</i> in advance or <i>$15</i> at the door</span>
          </p>
          <div class="pic-container">
            <a class="img-link" target="_blank">
              <img target="_blank" class="img" src="../assets/Mint-Poster.png">
            </a>
          </div>
          <p></p>
          <p>
            <span>Can't wait and hope to see you there!</span>
          </p>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">06/03/2022</p>
        <div class="news-content">
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://open.spotify.com/playlist/30HT71iSiHUlrHO27E9OmD?si=31f06e2cf82f4212">
              <img target="_blank" href="https://open.spotify.com/playlist/30HT71iSiHUlrHO27E9OmD?si=31f06e2cf82f4212" class="img" src="../assets/jun-22-small.jpeg">
            </a>
          </div>
          <p></p>
          <div>
            <ul class="real-list">
              <li><i>Burned</i> - <a target="_blank" href="https://linktr.ee/bloodlemon">Blood Lemon</a></li>
              <li><i>Some Future</i> - <a target="_blank" href="https://open.spotify.com/artist/3tPw9rzquFhEcPRLIjbRKQ">Forgivers</a></li>
              <li><i>Capacity for Illusion</i> - <a target="_blank" href="https://linktr.ee/uvcband">Ultraviolet Communication</a></li>
              <li><i>THE MAN</i> - <a target="_blank" href="https://open.spotify.com/artist/7dmbckQLtacSogGixbzrQp">The Alive!</a></li>
              <li><i>Prey</i> - <a target="_blank" href="https://open.spotify.com/artist/6mpRY8YgITonO5nxvfB4sU">The Rosies</a></li>
              <li><i>All There Is</i> - <a target="_blank" href="https://linktr.ee/darkbirdband">Darkbird</a></li>
              <li><i>Fruiting Body</i> - <a target="_blank" href="https://goon.ffm.to/ochre">GOON</a></li>
              <li><i>Bends of Life</i> - <a target="_blank" href="https://linktr.ee/heffnerband">Heffner</a></li>
              <li><i>The Real World</i> - <a target="_blank" href="https://linktr.ee/terracehouseband">Terrace House</a></li>
              <li><i>The Kick</i> - <a target="_blank" href="https://linktr.ee/TenEightyTrees">Ten Eighty Trees</a></li>
              <li><i>The Hurt Within</i> - <a target="_blank" href="https://linktr.ee/HolyCoves">Holy Coves</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">05/05/2022</p>
        <div class="news-content">
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://open.spotify.com/playlist/5csyvCkqOeD0ArJxSFywP6?si=d0fa2a8abbe84011">
              <img target="_blank" href="https://open.spotify.com/playlist/5csyvCkqOeD0ArJxSFywP6?si=d0fa2a8abbe84011" class="img" src="../assets/mayplaylist.png">
            </a>
          </div>
          <p></p>
          <div>
            <ul class="real-list">
              <li><i>Something About You</i> - <a target="_blank" href="https://linktr.ee/kirtymusic">Kirty</a></li>
              <li><i>Inhale, Exhale, Joyride</i> - <a target="_blank" href="https://www.instagram.com/littletripsco/">Little Trips</a></li>
              <li><i>Older</i> - <a target="_blank" href="https://music.supertentacles.com/kingofnothingalbum">Supertentacles</a></li>
              <li><i>Not To Worry</i> - <a target="_blank" href="https://www.sonsofluther.com">Sons of Luther</a></li>
              <li><i>The Only Person That I Like</i> - <a target="_blank" href="https://open.spotify.com/artist/0ylga3Dh1VWclizTFvim8I">Trash Cat</a></li>
              <li><i>Mind's Eye</i> - <a target="_blank" href="https://linktr.ee/henrychadwick">Henry Chadwick</a></li>
              <li><i>The Cut</i> - <a target="_blank" href="https://linktr.ee/slowfictionband">Slow Fiction</a></li>
              <li><i>Stole Your Car</i> - <a target="_blank" href="https://open.spotify.com/artist/5aBDMf9T5XYIR7roQb9DUR">BITTERS</a></li>
              <li><i>Off White</i> - <a target="_blank" href="https://linktr.ee/meyru">Meyru</a></li>
              <li><i>Space Abound</i> - <a target="_blank" href="https://linktr.ee/lkffct">LKFFCT</a></li>
              <li><i>Retrograde</i> - <a target="_blank" href="https://open.spotify.com/artist/5BkyMH9N4TovRgWF4IGd8W">Megawave</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">04/26/2022</p>
        <div class="news-content">
          <p>
            <span>What a great week! Appreciate the kind review of <b><i>Departures</i></b> by</span>
            <span><a target="_blank" href="https://www.divideandconquermusic.com/indie-music-album-reviews/">Divide and Conquer Music</a>!</span>
            <span>We're so happy to hear that the album is getting some love!</span>
          </p>
          <p>
            <span><a target="_blank" href="https://www.divideandconquermusic.com/indie-music-album-reviews/super-ok-departures">Check out the review here!</a></span>
          </p>
          <div class="pic-container at-bottom">
            <a class="img-link" target="_blank" href="https://www.divideandconquermusic.com/indie-music-album-reviews/super-ok-departures">
              <img target="_blank" href="https://www.divideandconquermusic.com/indie-music-album-reviews/super-ok-departures" class="img" src="../assets/d&cmusic-departures-review.png">
            </a>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">04/22/2022</p>
        <div class="news-content">
          <p>
            <span>Happy to announce that our debut album, Departures, as been reviewed by</span>
            <span><a target="_blank" href="https://dopecausewesaid.com/">DOPECAUSEWESAID</a>!</span>
            <span>We super appreciate the very kind words and we are so honored that they checked it out!</span>
          </p>
          <p>
            <span><a target="_blank" href="https://dopecausewesaid.com/dope-features/review-departures-album-by-super-ok">Check out the review here!</a></span>
          </p>
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://dopecausewesaid.com/dope-features/review-departures-album-by-super-ok">
              <img target="_blank" href="https://dopecausewesaid.com/dope-features/review-departures-album-by-super-ok" class="img" src="../assets/dopereview.png">
            </a>
          </div>
          <p style="margin-top: 35px;">
            <span>We also got a chance to chat with them to talk about the album, some of our process with it, how we got together, and what's next!</span>
          </p>
          <p>
            <span><a href="https://dopecausewesaid.com/dope-features/review-departures-album-by-super-ok">Check out the interview here!</a></span>
          </p>
          <div class="pic-container at-bottom">
            <a class="img-link" target="_blank" href="https://dopecausewesaid.com/dope-features/interview-los-angeles-based-rock-band-super-ok-talk-about-their-debut-album-departures">
              <img target="_blank" href="https://dopecausewesaid.com/dope-features/interview-los-angeles-based-rock-band-super-ok-talk-about-their-debut-album-departures" class="img" src="../assets/dopeinterview.png">
            </a>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">04/05/2022</p>
        <div class="news-content">
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://open.spotify.com/playlist/1tMSXj6Bw3p38uACz6PAzo?si=cac63203a441489d">
              <img target="_blank" href="https://open.spotify.com/playlist/1tMSXj6Bw3p38uACz6PAzo?si=cac63203a441489d" class="img" src="../assets/apr.jpeg">
            </a>
          </div>
          <p></p>
          <div>
            <ul class="real-list">
              <li><i>Solipsism</i> - <a target="_blank" href="https://open.spotify.com/artist/2cFndrtHA9h7w3MPQawxyB">Geez Louise</a></li>
              <li><i>I Want Your Love</i> - <a target="_blank" href="https://linktr.ee/kirtymusic">Kirty</a></li>
              <li><i>Anxiety</i> - <a target="_blank" href="https://bio.site/TheSafetyMeeting">The Safety Meeting</a></li>
              <li><i>Melt</i> - <a target="_blank" href="https://swarm666clump.bandcamp.com/album/leaks">Swim Camp</a></li>
              <li><i>The Tempest</i> - <a target="_blank" href="https://open.spotify.com/artist/0e52fRrgWuE2V31MUOKzTt">Phantomelo</a></li>
              <li><i>War Machine</i> - <a target="_blank" href="https://linktr.ee/the.revies">The Revies</a></li>
              <li><i>You Don't Even Know Him</i> - <a target="_blank" href="https://linktr.ee/heffnerband">Heffner</a></li>
              <li><i>Park Place</i> - <a target="_blank" href="https://linktr.ee/smallreactions">Small Reactions</a></li>
              <li><i>Easy to Forget</i> - <a target="_blank" href="https://linktr.ee/closebye">Closebye</a></li>
              <li><i>Coffee</i> - <a target="_blank" href="https://www.instagram.com/juliensdaughter/">Julien's Daughter</a></li>
              <li><i>Morning Hum</i> - <a target="_blank" href="https://linktr.ee/motelblack">Motel Black</a></li>
              <li><i>Let Her Be</i> - <a target="_blank" href="https://linktr.ee/astrinamusic">ASTRINA</a></li>
              <li><i>Headlights U.S.A.</i> - <a target="_blank" href="https://www.instagram.com/gold_star_music/">Gold Star</a></li>
              <li><i>Mind Control</i> - <a target="_blank" href="https://www.badbuddy.band/links/">Bad Buddy</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">03/07/2022</p>
        <div class="news-content">
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://open.spotify.com/playlist/6WAgdjhK0SwZXRTiJf5HGs?si=30a92afd407b43cd7">
              <img target="_blank" href="https://open.spotify.com/playlist/6WAgdjhK0SwZXRTiJf5HGs?si=30a92afd407b43cd" class="img" src="../assets/march-cover-1.jpeg">
            </a>
          </div>
          <p></p>
          <div>
            <ul class="real-list">
              <li><i>Beyond the Veil</i> - <a target="_blank" href="https://open.spotify.com/artist/7aHzvcihEm0ooRMlM4d2km">Misery Whip</a></li>
              <li><i>Destiny</i> - <a target="_blank" href="https://fzzg.gs/">The Fizzgigs</a></li>
              <li><i>Silver Lining</i> - <a target="_blank" href="https://open.spotify.com/artist/7fvP2LgSQyfvtnWN3G0eq9">Munsi</a></li>
              <li><i>Bobby Fuller Died for Your Sins</i> - <a target="_blank" href="https://linktr.ee/chuckprophet">Chuck Prophet</a></li>
              <li><i>Shade</i> - <a target="_blank" href="https://linktr.ee/GrungePopRecords">Rockford</a></li>
              <li><i>Rosemary</i> - <a target="_blank" href="https://www.instagram.com/thepricklypairmusic/">The Prickly Pair</a></li>
              <li><i>The Kite</i> - <a target="_blank" href="https://linktr.ee/notfromenglanddd">Not From England</a></li>
              <li><i>Anybody Else</i> - <a target="_blank" href="https://www.instagram.com/kaliflanagan/">KALI</a></li>
              <li><i>Lifeboat</i> - <a target="_blank" href="https://open.spotify.com/artist/1D6iT7p1UgSOEkWg54vMMZ">Salmon Doza</a></li>
              <li><i>Drunk</i> - <a target="_blank" href="https://www.instagram.com/pandaxnoir/">Panda Noir</a></li>
              <li><i>Subcultured</i> - <a target="_blank" href="https://open.spotify.com/artist/4JlKf796rnWKhzbN1le14u">Easy Sleeper</a></li>
              <li><i>Weather the Storm</i> - <a target="_blank" href="https://open.spotify.com/artist/76JwlkXcpyVuS3H6hUKUYy">Gray Fields</a></li>
              <li><i>Always on Our Minds</i> - <a target="_blank" href="https://linktr.ee/Bigtroppy">Big Troppy</a></li>
              <li><i>Alias</i> - <a target="_blank" href="https://linktr.ee/Savoir_Faire">Savoir Faire</a></li>
              <li><i>Don't Mind Me</i> - <a target="_blank" href="https://linktr.ee/captainkad">Spaceyy</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">02/20/2022</p>
        <div class="news-content">
          <p>
            <span>The video of our first ever interview from January 2022 with <a href="https://www.facebook.com/TheStoverloadPodcast" target="_blank">Mike Stover of The Stoverload Music Podcast</a> is out!</span>
          </p>
          <p>
            <span>Check out the video below</span>
            <span>(or on <a href="https://youtu.be/_lRWZFCHdtI" target="_blank">YouTube</a>)</span>
            <span>or listen on</span>
            <span>
              <a href="https://open.spotify.com/episode/4paCNlO1yv2hEDzMFdntHd?si=u91WYfatQESEdSI0SqnB_A&fbclid=IwAR1FSQ8Ta59FQZ1rWcIimJKqFezRzn3-a9fy9A_m-VZuJqdZ_QHz3slyGaY&nd=1" target="_blank">Spotify</a>
            </span>
            <span>or</span>
            <span>
              <a href="https://stoverloadpodcast.podbean.com/e/stoverload-music-podcast-feat-super-ok/" target="_blank">Podbean</a>
            </span>
            <span>now!</span>
          </p>
          <p>
            <span>We want to shout out Mike who is constantly hustling on behalf of up-and-coming artists by, in addition to great interviews, introducing fans to new music in episodes of wall-to-wall music called “rock blocks.”</span>
            <span>Such a rad, throwback way to find your next earworm or favorite band.</span>
          </p>
          <p></p>
          <div class="video-container" id="stover-interview-video">
            <iframe :height="videoHeight" class="video youtube-video" src='https://www.youtube.com/embed/_lRWZFCHdtI' frameborder='0' allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">02/07/2022</p>
        <div class="news-content">
          <p>
            <span>Hey all!</span>
            <span>We're introducing a monthly playlist of our new favorite tunes from up-and-coming artists, starting with our February Indie Favorites playlist:</span>
          </p>
          <p></p>
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://open.spotify.com/playlist/04CGPOKylihJFjQpseqgXm?si=a2ae7b2623af4a07">
              <img target="_blank" href="https://open.spotify.com/playlist/04CGPOKylihJFjQpseqgXm?si=a2ae7b2623af4a07" class="img" src="../assets/Indie-Favs-Feb-22.jpeg">
            </a>
          </div>
          <p></p>
          <p>
            <span>Our current thinking is that we'll create these monthly and update them throughout the month.</span>
            <span>And if you're not down with Spotify, we totally get it - below is the song and groups in the list for you to check out!</span>
          </p>
          <div>
            <ul class="real-list">
              <li><i>1994</i> - <a target="_blank" href="https://linktr.ee/superbloomnyc">Superbloom</a></li>
              <li><i>Sweet</i> - <a target="_blank" href="https://www.savoirfairemusic.com">Savoir Faire</a></li>
              <li><i>The Wrong Way</i> - <a target="_blank" href="https://www.sonsofluther.com">Sons of Luther</a></li>
              <li><i>All Is Fair (In Love and Warfare)</i> - <a target="_blank" href="https://linktr.ee/deerpilot">Deer Pilot</a></li>
              <li><i>Outta Love</i> - <a target="_blank" href="https://linktr.ee/andremiguel">André Miguel</a></li>
              <li><i>A Midnight Face</i> - <a target="_blank" href="https://www.instagram.com/emperorsnight">Emperors Night</a></li>
              <li><i>MD</i> - <a target="_blank" href="https://linktr.ee/dynamofuzz">Dynamo Fuzz</a></li>
              <li><i>Sunshine Subconscious</i> - <a target="_blank" href="https://www.handdrawnmapsofficial.com/">Hand Drawn Maps</a></li>
              <li><i>Walk in the Park</i> - <a target="_blank" href="https://monogroove.bandcamp.com/">Monogroove</a></li>
              <li><i>Tastebuds</i> - <a target="_blank" href="https://open.spotify.com/artist/6dPTjMbZSRJhoUUt453nB8">rgb</a>, <a target="_blank" href="https://open.spotify.com/artist/31cttREtp2nJws3hBGhTEC">Bayler</a>, <a target="_blank" href="https://open.spotify.com/artist/6rQzOdpxG7oK3DKnpJ3sEs">Mowen$</a> </li>
              <li><i>Tune in, Drop Out</i> - <a target="_blank" href="https://open.spotify.com/artist/55bBJNuY224tmQf4odUqXV">Beach Riot</a></li>
              <li><i>Revolution</i> - <a target="_blank" href="https://www.lacedinblue.com/">Laced in Blue</a></li>
              <li><i>Another World</i> - <a target="_blank" href="https://www.littlegalaxiesband.com/">Little Galaxies</a></li>
              <li><i>I Don't Mind</i> - <a target="_blank" href="https://linktr.ee/titowoods">Tito Woods</a></li>
              <li><i>Chihiro</i> - <a target="_blank" href="https://linktr.ee/softblueshimmer">Soft Blue Shimmer</a></li>
              <li><i>Earth Is A Black Hole</i> - <a target="_blank" href="https://linktr.ee/teenagewrist">Teenage Wrist</a></li>
              <li><i>What You Can't Kill</i> - <a target="_blank" href="https://www.merciraines.com/havemerci">Merci Raines</a></li>
              <li><i>A Prism</i> - <a target="_blank" href="https://linktr.ee/theweltonshipwreck">The Welton Shipwreck</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">01/29/2022</p>
        <div class="news-content">
          <p>
            <span>If you're looking for some new music, check out The Stoverload Music Podcast today around 2 ET.</span>
            <span>A new episode of the "rock block," #14, is dropping them, with about 90-minutes of kickass music from the underground.</span>
            <span>It's a great way to support up-and-coming artists! And hey, guess who's on the docket today? 😉</span>
          </p>
          <p><span>While you're there, take a look at the last interview episode Mike did - it was with us!</span></p>
          <p>
            <span>Check it out on</span>
            <span>
              <a target="_blank" href="https://open.spotify.com/show/2GFUSVlwJ8Trv5Vy3ZyHsr">Spotify</a>
            </span>
            <span>and</span>
            <span>
              <a target="_blank" href="https://stoverloadpodcast.podbean.com/">Podbean</a>
            </span>
            <span>now!</span>
          </p>
          <p></p>
          <div class="pic-container at-bottom">
            <a class="img-link" target="_blank" href="https://open.spotify.com/show/2GFUSVlwJ8Trv5Vy3ZyHsr">
              <img target="_blank" href="https://open.spotify.com/show/2GFUSVlwJ8Trv5Vy3ZyHsr" class="img" src="../assets/stoverload-14.jpeg">
            </a>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">01/22/2022</p>
        <div class="news-content">
          <p>
            <span>It’s up!</span>
            <span>Our first interview!</span>
            <span>We had the honor and privilege of chatting with <a href="https://www.facebook.com/TheStoverloadPodcast" target="_blank">Mike Stover</a> about our upcoming album release, our origin story dating back to 2018, and our experience recording and assembling a record remotely in a pandemic.</span>
          </p>
          <p>
            <span>Check it out on</span>
            <span>
              <a href="https://open.spotify.com/episode/4paCNlO1yv2hEDzMFdntHd?si=u91WYfatQESEdSI0SqnB_A&fbclid=IwAR1FSQ8Ta59FQZ1rWcIimJKqFezRzn3-a9fy9A_m-VZuJqdZ_QHz3slyGaY&nd=1" target="_blank">Spotify</a>
            </span>
            <span>or</span>
            <span>
              <a href="https://stoverloadpodcast.podbean.com/e/stoverload-music-podcast-feat-super-ok/" target="_blank">Podbean</a>
            </span>
            <span>now!</span>
          </p>
          <p></p>
          <div class="video-container" id="stover-interview-teaser">
            <iframe :height="videoHeight" class="video youtube-video" src='https://www.youtube.com/embed/rx-VUKSfG88' frameborder='0' allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">12/25/2021</p>
        <div class="news-content">
          <p>
            <span>It’s finally time!</span>
            <span>We are proud to announce our debut album, <b><i>Departures</i></b>, drops January 28th!!</span>
          </p>
          <p><a target="_blank" href="https://distrokid.com/hyperfollow/superok/departures">Pre-save or pre-order now!!</a></p>
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://distrokid.com/hyperfollow/superok/departures">
              <img target="_blank" href="https://distrokid.com/hyperfollow/superok/departures" class="img" src="../assets/Departures-Front-2-small.jpeg">
            </a>
          </div>
          <p style="margin-top: 20px;">Produced by <a href="https://www.instagram.com/chrisharvey86/" target="_blank">Chris Harvey</a> and mastered by <a href="https://www.instagram.com/therosswilson/" target="_blank">Ross Wilson</a>.</p>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">12/01/2021</p>
        <div class="news-content">
          <div class="pic-container">
            <a class="img-link" target="_blank" href="https://open.spotify.com/artist/24Y8uCyQ4xwFDutTtfI47D?si=xXDTNIA7QwyWD4VqFZbFzg&nd=1">
              <img target="_blank" href="https://open.spotify.com/artist/24Y8uCyQ4xwFDutTtfI47D?si=xXDTNIA7QwyWD4VqFZbFzg&nd=1" class="img" src="../assets/SuperOK_Spotify_2021.jpeg">
            </a>
          </div>
          <p style="margin-top: 20px;">
            <span>Wow - this blew our expectations out of the water!</span>
            <span>We deeply appreciate all of the support we’ve gotten from friends & family, but we want to give an extra big thank-you to everyone who had no idea who we are but gave us a listen anyways.</span>
          </p>
          <p><span>We can’t wait to share our LP, and a whole bunch of tasty treats with it, in the new year 💙💜💚🧡</span></p>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">11/19/2021</p>
        <div class="news-content">
          <p>
            <span>We have brand-spankin’-new photos of us thanks to our intrepid photographer <a href="https://www.instagram.com/markerosen/" target="_blank">Mark Rosen</a> who dodged traffic and m&m’s being thrown out of a car window to get some incredible shots of us (and a big thank you to our friend
            <a href="https://www.instagram.com/j_slash_k/" target="_blank">John Kerr</a> for helping out with the absolutely <i>dope</i> lighting).</span>
            <span>We’re so happy to finally have some pictures with Drew that aren’t selfies, and some updated photos to put up all over the internet.</span>
          </p>
          <div class="pic-container">
            <img target="_blank" class="img" src="../assets/new-photos/SUPEROK_2.jpg">
          </div>
          <p style="margin-top: 30px;">The less obvious news is that our first album has finished being mixed! (!!!!)</p>
          <p>Thanks to the incomparable talents of <a href="https://www.instagram.com/chrisharvey86/" target="_blank">Chris Harvey</a>, 13 songs (including mastered versions of the 4 singles we released this year) are on their way to your ears for your listening pleasure. That’s all we’re gonna say about it for now - stay tuned…</p>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">07/29/2021</p>
        <div class="news-content">
          <p>Sat. July 31st at 6pm ET - Super OK will be on The Stoverload Podcast Rock Block #9 with a new song off our upcoming LP.</p>
          <p>You haven't heard this one yet! Check it out and while you're at it, maybe find a new favorite band among all the other awesome groups you'll hear.</p>
          <p>Check it out <a target="_blank" href="https://open.spotify.com/episode/6l6JEuaShkzloSLmQcJMm8?si=82b6ca68539e42d0">on Spotify</a> or <a target="_blank" href="https://stoverloadpodcast.podbean.com">here</a>! We come up at about 67:45!</p>
          <p></p>
          <div class="video-container" id="push-teaser">
            <video src="../assets/Push_Teaser.mp4" controls preload="auto" type="video/mp4"></video>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">06/21/2021</p>
        <div class="news-content">
          <p>
            <span>We're thrilled to introduce y'all to our new drummer <a target="_blank" href="https://www.instagram.com/coloryoudrew/">Drew</a>!!</span>
            <span>Drew is a mega talented drummer coming to us from <a target="_blank" href="https://www.instagram.com/coloryouband/">Color You</a>, an LA-based psychedelic rock band that was touring in pre-covid times.</span>
            <span>He's been drumming for about 13 years and says much of that has been heavily influenced by the man himself, Dave Grohl. As you can imagine, he fits in well with us!</span>
          </p>
          <div class="pic-container at-bottom">
            <a class="img-link" target="_blank" href="https://www.instagram.com/coloryoudrew/">
              <img target="_blank" href="https://www.instagram.com/coloryoudrew/" class="img" src="../assets/Drew.jpeg">
            </a>
          </div>
        </div>
      </div>
      <div class="news" v-if="showAll">
        <p class="news-date">06/04/2021</p>
        <div class="news-content">
          <p>Our fourth single, <b>"Break the Faith"</b>, is available on all platforms! <span class="fake-link" v-on:click="$props.navigateToMusic()">Listen now!</span></p>
          <div class="pic-container at-bottom">
            <img v-on:click="$props.navigateToMusic()" target="_blank" class="img clickable" src="../assets/Break-the-Faith-cover.jpg" alt="Break the Faith Single Cover">
          </div>
        </div>
      </div>
      <div style="margin-top: 15px;" v-if="showAll">
        <div class="news">
          <p class="news-date">05/22/2021</p>
          <div class="news-content">
            <p>We’re thrilled to announce that our 4th single, “Break the Faith”, will be released on June 4th!!</p>
            <p>Check out Davey playing part of the song and <a target="_blank" href="https://distrokid.com/hyperfollow/superok/break-the-faith">pre-Save on Spotify, Amazon, or Apple Music now!!</a></p>
            <p></p>
            <div class="video-container" id="btf-solo">
              <video src="../assets/Break_the_Faith_Solo-low_res.mp4" controls preload="auto" type="video/mp4"></video>
            </div>
          </div>
        </div>
        <div class="news">
          <p class="news-date">04/23/2021</p>
          <div class="news-content">
            <p>Our third single, <b>"Eat Us Alive"</b>, is available on all platforms! <span class="fake-link" v-on:click="$props.navigateToMusic()">Listen now!</span></p>
            <div class="pic-container at-bottom">
              <img v-on:click="$props.navigateToMusic()" target="_blank" class="img clickable" src="../assets/Eat-Us-Alive-cover-smaller.jpg" alt="Eat Us Alive Single Cover">
            </div>
          </div>
        </div>
        <div class="news">
          <p class="news-date">04/16/2021</p>
          <div class="news-content">
            <p>Ready for new music? Friday, 4/23/2021, our new single "Eat Us Alive" hits all major streaming platforms!</p>
            <p></p>
            <p><a target="_blank" href="https://distrokid.com/hyperfollow/superok/eat-us-alive">Pre-Save on Spotify now!</a></p>
            <div class="video-container" id="eua-teaser">
              <video src="../assets/Eat_Us_Alive_Teaser.mp4" controls></video>
            </div>
          </div>
        </div>
        <div class="news">
          <p class="news-date">04/07/2021</p>
          <div class="news-content">
            <p>We were featured in Voyage LA Magazine! <a target="_blank" href="http://voyagela.com/interview/meet-super-ok-west-side/">Check out the interview here!</a></p>
            <div class="pic-container">
              <a class="img-link" target="_blank" href="http://voyagela.com/interview/meet-super-ok-west-side/">
                <img target="_blank" href="http://voyagela.com/interview/meet-super-ok-west-side/" class="img" src="../assets/VoyageLA-SuperOK-2.png" alt="Voyage LA Interview Picture">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import GallerySlides from "@/components/subcomponents/GallerySlides";

export default {
  name: 'News',
  components: {GallerySlides},
  props: {
    navigateToMusic: Function,
    showAll: Boolean,
  },
  data: () => ({
    videoHeight: 450,
  }),
  created() {
    window.addEventListener("resize", this.changeWidth);
  },
  mounted() {
    this.changeWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.changeWidth);
  },
  methods: {
    changeWidth() {
      const baseList = document.getElementsByClassName('youtube-video');
      if (baseList.length > 0) {
        const baseVid = baseList[0];
        this.videoHeight = baseVid.clientWidth / 1.777777;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .info {
    display: flex;
    align-items: center;
    .info-label {
      margin-left: 15px;
      font-weight: bold;
      font-size: 20px;
    }
    margin-bottom: 10px;
  }
  .section {
    padding: 10px 15px;
    // text-indent: 20px;
  }
  span {
    margin-right: 5px;
  }
  p {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .news {
    border-bottom: 1px solid rgba(44, 62, 80, .5);
    margin-bottom: 20px;
    padding-bottom: 10px;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .news-content {
    margin: 15px 15px 20px;
  }
  .news-date {
    font-style: oblique;
    font-weight: 500;
  }
  .read-more {
    margin-top: 10px;
    font-style: italic;
    cursor: pointer;
  }
  video {
    width: 100%;
    height: auto;
    -webkit-box-shadow: 0px -1px 16px 5px #828282;
    box-shadow: 0px -1px 16px 5px #828282;
  }
  .video-container {
    margin: 20px 0 10px;
  }
  .pic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    &.at-bottom {
      margin-bottom: 25px;
    }
  }
  .img-link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img {
    border-radius: 10px;
    -webkit-box-shadow: 0px -1px 16px 5px #828282;
    box-shadow: 0px -1px 16px 5px #828282;
    width: 100%;
    max-width: 400px;
  }
  .clickable {
    cursor: pointer;
  }
  .fake-link {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    /*input has OS specific font-family*/
    color: #069;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  .video-container .video,
  #btf-solo,
  #push-teaser,
  #eua-teaser {
    width: 80%;
    margin: 35px 10%;
  }

  .real-list {
    list-style: initial;
    margin-left: 40px;
    margin-top: 15px;
  }

  @media (max-width: 600px) {
    .video-container .video,
    #btf-solo {
      width: 90%;
      margin: 35px 5%;
    }
  }
  @media (max-width: 800px) {
    .video-container .video,
    #btf-solo {
      width: 100%;
      margin: 35px 0;
    }
  }
  @media (max-width: 700px) {
    .section {
      padding: 10px 0;
    }
  }
</style>
