export const pastShowDict = {
  'the-mint-6.18': {
    id: 'the-mint-6.18',
    date: "06/18/2022",
    shortDate: "6/18/22",
    name: "The Mint",
    performingWith: `<span>Elise Carver,</span>
      <span><a target="_blank" href="https://www.instagram.com/eaglerockgospel/">The Eagle Rock Gospel Singers</a>,</span>
      <span><a target="_blank" href="https://www.instagram.com/itsjillianann/">Jillian Ann</a>,</span>
      <span>and <a target="_blank" href="https://www.instagram.com/the.camillerose/">Camille Rose</a>!</span>`,
    address: `6010 W Pico Blvd, Los Angeles, CA 90035`,
    shortLocation: 'Los Angeles, CA',
    gallery: {
      key: 'mint',
      total: 10
    }
  },
  'harvard-and-stone-7.19': {
    id: 'harvard-and-stone-7.19',
    // imgSrc: 'superok-h&s.jpg',
    date: "07/19/2022",
    shortDate: "7/19/22",
    name: "Harvard & Stone",
    performingWith: `<span><a target="_blank" href="https://www.instagram.com/deerpilot/">Deer Pilot</a>,</span>
      <span>and <a target="_blank" href="https://www.instagram.com/kawaguchi_band/">Kawaguchi</a>!</span>`,
    address: `5221 Hollywood Blvd, Los Angeles, CA 90027`,
    shortLocation: 'Los Angeles, CA',
    gallery: {key: 'harvard-and-stone', total: 4}
  },
  'barrel-tavern-7.30': {
    id: 'barrel-tavern-7.30',
    date: "07/30/2022",
    shortDate: "7/30/22",
    name: "The Barrel Tavern",
    performingWith: `<span><a target="_blank" href="https://www.instagram.com/kawaguchi_band/">Kawaguchi</a>!</span>`,
    address: `4547 Van Nuys Blvd Sherman Oaks, CA 91403`,
    shortLocation: 'Sherman Oaks, CA',
    gallery: {key: 'barrel-tavern', total: 10}
  },
  'molly-malones-8.20': {
    id: 'molly-malones-8.20',
    date: "08/20/2022",
    shortDate: "8/20/22",
    name: "Molly Malone's Irish Pub",
    liveAt: `
        <span>Presented by</span>
        <span><a target="_blank" href="https://www.facebook.com/AFrostpresents">Angela Frost</a></span>,
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/mollymalonesla/">Molly Malone's Irish Pub</a>`,
    // todo: make this smarter
    performingWith: `<span><a target="_blank" href="https://withkoji.com/@ROKTOY">Roktoy</a>,</span>
      <span><a target="_blank" href="https://www.instagram.com/matchhouseband/">MatchHouse</a>,</span>
      <span>and <a target="_blank" href="https://www.instagram.com/deafboutique/">Deafboutique</a>!</span>`,
    timeDetails: [
      `<span>Deafboutique - <i>8:20</i></span>`,
      `<span><b>Super OK - <i>9:20</i></b></span>`,
      `<span>MATCHHOUSE - <i>10:20</i></span>`,
      `<span>Roktoy - <i>11:20</i></span>`
    ],
    address: `575 S Fairfax Ave Los Angeles, CA 90036`,
    shortLocation: 'Los Angeles, CA',
    addressLink: `https://g.page/mollymalonela?share`,
    gallery: {key: 'molly-malones', total: 10}
  },
  'state-weho-9.9': {
    id: 'state-weho-9.9',
    imgSrc: 'SuperOK-StateWeHo.jpg',
    date: "09/09/2022",
    shortDate: "9/9/22",
    name: "State Social House",
    liveAt: `
        <span>Presented by</span>
        <span><a target="_blank" href="https://www.facebook.com/AFrostpresents">Angela Frost</a></span>,
        <span>catch Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/stateweho/">State Social House</a>`,
    // todo: make this smarter
    timeDetails: [`<span>Doors - 8pm</span>`, `<span><b>Super OK - <i>9:50pm</i></b></span>`],
    address: `8782 Sunset Blvd, W. Hollywood, CA 90069 `,
    shortLocation: 'West Hollywood, CA',
    addressLink: `https://goo.gl/maps/rUaQetwcWuQNGgqi8`,
  },
  'the-whisky-10.15': {
    id: 'the-whisky-10.15',
    imgSrc: 'SUPER-OK-whiskey-V1-smallest.png',
    date: "10/15/2022",
    shortDate: "10/15/22",
    name: "The Whisky a Go Go",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/thewhiskyagogo/">The Whisky a Go Go</a>`,
    timeDetails: [
      `<span>Doors - 6:30pm</span>`,
      `<span><b>Super OK - <i>7:00pm</i></b></span>`
    ],
    address: `8901 Sunset Blvd, West Hollywood, CA 90069`,
    shortLocation: 'West Hollywood, CA',
    addressLink: `https://goo.gl/maps/f3DikcHvhYqZd3XY9`,
  },
  'el-cid-11.17': {
    id: 'el-cid-11.17',
    imgSrc: 'ElCidPoster.jpeg',
    date: "11/17/2022",
    shortDate: "11/17/22",
    name: "El Cid",
    liveAt: `
        <div style="margin-top: 10px"></div>
        <span>Presented by</span>
        <span><a target="_blank" href="https://www.facebook.com/AFrostpresents">Angela Frost</a></span>,
        <span>catch Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/elcidsunset/">El Cid</a>`,
    venueDetails: [
      `<i>All ages</i>`,
      `<b>Reach out for tix! (<a href="https://www.instagram.com/wearesuperok/" target="_blank">DM</a> or <a href="mailto:tix@superok.band">email</a></div>)</b>`
    ],
    timeDetails: [
      `<span>Doors - 6pm</span>`,
      `<span>Jack/Sam Nooney</span>`,
      `<span>Meat Casino</span>`,
      `<span><b>Super OK - <i>7:45pm</i></b></span>`,
      `<span>Yes, Please</span>`,
    ],
    address: `4212 Sunset Blvd, Los Angeles, CA 90029`,
    shortLocation: 'Los Angeles, CA',
    addressLink: `https://goo.gl/maps/oJMQjuLuMBzNN5Qa7`,
  },
  'breaking-sound-at-harvard-and-stone-3.2.23': {
    id: 'breaking-sound-at-harvard-and-stone-3.2.23',
    imgSrc: 'H&S0302_SuperOk.png',
    date: "03/02/2023",
    shortDate: "3/2/23",
    name: "Harvard & Stone",
    liveAt: `
        <span>Super OK playing</span>
        <a target="_blank" href="https://www.instagram.com/breakingsound/">Breaking Sound</a>
        <span>at</span>
        <a target="_blank" href="https://www.instagram.com/breakingsound/">Harvard & Stone</a>`,
    performingWith: `<span><a target="_blank" href="https://biglink.to/hellostranger">Hello Stranger</a>,</span>
      <span>and <a target="_blank" href="https://linktr.ee/HUM.BUG">Humbug</a>!</span>`,
    address: `5221 Hollywood Blvd, Los Angeles, CA 90027`,
    shortLocation: 'Los Angeles, CA',
  },
  'the-viper-room-5.18.23': {
    id: 'the-viper-room-5.18.23',
    imgSrc: 'SUPER-OK-VIPER-ROOM-11X17-small.jpg',
    date: "05/18/2023",
    shortDate: "5/18/23",
    name: "The Viper Room",
    liveAt: `
        <span>Super OK playing</span>
        <span>at</span>
        <a target="_blank" href="https://www.instagram.com/theviperroom/">The Viper Room</a>`,
    address: `8852 Sunset Blvd, West Hollywood, CA 90069`,
    shortLocation: 'West Hollywood, CA',
  },
  'buds-of-bruin-zine-release-5.29': {
    id: 'buds-of-bruin-zine-release-5.29',
    imgSrc: 'budsofbruin.png',
    date: "05/29/2023",
    shortDate: "05/29/23",
    name: "The Good Nite",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/thegoodnite/">The Good Nite</a>
        <span>for the</span>
        <a target="_blank" href="https://www.instagram.com/budsofbruin/">Buds of Bruin</a>
        <span>May Zine Release party!!</span>`,
    address: `10721 Burbank Blvd, North Hollywood, CA 91601`,
    shortLocation: 'North Hollywood, CA',
    addressLink: `https://goo.gl/maps/7389bvQcyBJvzcX88`,
  },
  'the-whisky-6.21': {
    id: 'the-whisky-6.21',
    imgSrc: 'whisky-banner-1-small.jpg',
    date: "06/21/2023",
    shortDate: "06/21/23",
    name: "The Whisky a Go Go",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/thewhiskyagogo/">The Whisky a Go Go</a>`,
    venueDetails: [`<i>21+</i>`, `<a target="_blank" href="https://www.eventbrite.com/e/super-ok-at-the-viper-room-tickets-630502880087">$20 in advance</a></b>`],
    timeDetails: [
      `<span>Doors - 6:30pm</span>`,
      `<span><b>Super OK - <i>7:30pm</i></b></span>`
    ],
    address: `8901 Sunset Blvd, West Hollywood, CA 90069`,
    shortLocation: 'West Hollywood, CA',
    addressLink: `https://goo.gl/maps/f3DikcHvhYqZd3XY9`,
  },
  'harvelles-revival-7.28': {
    id: 'harvelles-revival-7.28',
    imgSrc: 'harvelles-revival-7.28.jpg',
    date: "07/28/2023",
    shortDate: "07/28/23",
    name: "Harvelle's Santa Monica",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/harvellessm/">Harvelle's Santa Monica</a>`,
    venueDetails: [`<i>21+</i>`, `$5 at the door`],
    timeDetails: [
      `<span>Doors - 8:00pm</span>`,
      `<span>Mean Seasons</span>`,
      `<span>Valley Echo</span>`,
      `<span>Addy Baddy</span>`,
      `<span><b>Super OK</b></span>`,
      `<span>Who Cares</span>`,
    ],
    address: `1432 4th Street Santa Monica 90401`,
    shortLocation: 'Santa Monica, CA',
    addressLink: `https://maps.app.goo.gl/B9bffTsyTFFJXjBs9`,
  },
  'buds-fest-10.7': {
    id: 'buds-fest-10.7',
    imgSrc: 'buds-fest.png',
    date: "10/07/2023",
    shortDate: "10/7/23",
    name: "The Offbeat",
    performingWith: `<span><a target="_blank" href="https://www.instagram.com/bastardo_gerardo/">Bastardo Gerardo</a>,</span>
      <span><a target="_blank" href="https://www.instagram.com/bruinjams/">BRUIN</a>,</span>
      <span><a target="_blank" href="https://www.instagram.com/deathlibero/">Leo de Libero</a></span>
      <span>and <a target="_blank" href="https://www.instagram.com/botchedexecution_hc/">Botched Execution</a>!</span>`,
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/theoffbeatbar/">The Offbeat</a>`,
    venueDetails: [`<i>21+</i>`, `$7 at the door`],
    timeDetails: [
      `<span>4:00pm to 9:00pm</span>`
    ],
    address: `6316 York Blvd, Los Angeles, CA 90042`,
    shortLocation: 'Los Angeles, CA',
    addressLink: `https://maps.app.goo.gl/YYLPadRX7SWvLkHp6`,
  },
  'molly-malones-12.15': {
    id: 'molly-malones-12.15',
    imgSrc: 'MM-12.15-md.jpg',
    date: "12/15/2023",
    shortDate: "12/15/23",
    name: "Molly Malone's Irish Pub",
    liveAt: `
        <span>Presented by</span>
        <span><a target="_blank" href="https://www.facebook.com/AFrostpresents">Angela Frost</a></span>,
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/mollymalonesla/">Molly Malone's Irish Pub</a>`,
    // venueDetails: [`<i>21+</i>`, `<a target="_blank" href="https://www.eventbrite.com/e/super-ok-at-molly-malones-tickets-773989763217">$10 in advance</a></b>`, `$15 at the door`],
    // timeDetails: [`<span>9:00pm</span>`],
    // link: `https://www.eventbrite.com/e/super-ok-at-molly-malones-tickets-773989763217`,
    address: `575 S Fairfax Ave Los Angeles, CA 90036`,
    shortLocation: 'Los Angeles, CA',
    // addressLink: `https://g.page/mollymalonela?share`,
  },
  'boomfest-3.30': {
    id: 'boomfest-3.30',
    imgSrc: 'Boomfest.png',
    date: "03/30/2024",
    shortDate: "3/30/24",
    name: "Boomtown Brewery",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/boomtownbrewery/">BOOMFEST</a>`,
    // venueDetails: [`<i>21+</i>`, `<a target="_blank" href="https://www.eventbrite.com/e/super-ok-at-molly-malones-tickets-773989763217">$10 in advance</a></b>`, `$15 at the door`],
    // timeDetails: [`<span>9:00pm</span>`],
    // link: `https://www.eventbrite.com/e/super-ok-at-molly-malones-tickets-773989763217`,
    address: `700 Jackson St Los Angeles, CA 90012 United States`,
    shortLocation: 'Los Angeles, CA',
    // addressLink: `https://g.page/mollymalonela?share`,
  },
  'molly-malones-4.25': {
    id: 'molly-malones-4.25',
    imgSrc: 'MMs-4.25.jpeg',
    date: "04/25/2024",
    shortDate: "4/25/24",
    name: "Molly Malone's Irish Pub",
    liveAt: `
        <span>Presented by</span>
        <span><a target="_blank" href="https://www.facebook.com/AFrostpresents">Angela Frost</a></span>,
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/mollymalonesla/">Molly Malone's Irish Pub</a>`,
    venueDetails: [`<i>21+</i>`, `<b>$10 in advance <i>(DM us)</i></b>`, `$15 at the door`],
    timeDetails: [`<span>8:20pm</span>`],
    // link: `https://www.eventbrite.com/e/super-ok-at-molly-malones-tickets-773989763217`,
    address: `575 S Fairfax Ave Los Angeles, CA 90036`,
    shortLocation: 'Los Angeles, CA',
    // addressLink: `https://g.page/mollymalonela?share`,
  },
  'boomfest-6.29': {
    id: 'boomfest-6.29',
    imgSrc: 'Boomfest-6.29-3.png',
    date: "06/29/2024",
    shortDate: "6/29/24",
    name: "Boomtown Brewery",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/boomtownbrewery/">BOOMFEST</a>`,
    address: `700 Jackson St Los Angeles, CA 90012 United States`,
    shortLocation: 'Los Angeles, CA',
    addressLink: `https://maps.app.goo.gl/EcNa3sj5MZL2n5xZ6`,
  },
  'harvelles-revival-7.26.24': {
    id: 'harvelles-revival-7.26.24',
    imgSrc: 'Harvelles-7.26.24.png',
    date: "07/26/2024",
    shortDate: "07/26/24",
    name: "Harvelle's Santa Monica",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/harvellessm/">Harvelle's Santa Monica</a>`,
    // venueDetails: [`<i>21+</i>`, '$10', "<a href='https://santamonica.harvelles.com/shows/269070'>Buy tickets" + " now</a>", `Or at the door`],
    timeDetails: [
      // `<span>Doors - 8:00pm</span>`,
      `<span><b>Super OK</b></span>`,
      `<span>aquínomás</span>`,
      `<span>Ultraviolet Communication</span>`,
      `<span>Temple Monarc</span>`,
      `<span>Dyes</span>`,
    ],
    address: `1432 4th Street Santa Monica 90401`,
    shortLocation: 'Santa Monica, CA',
    addressLink: `https://maps.app.goo.gl/B9bffTsyTFFJXjBs9`,
  },
  'bar-lubitsch-10.19.24': {
    id: 'bar-lubitsch-10.19.24',
    imgSrc: 'bar-lubitsch-10.19.24.png',
    date: "10/19/2024",
    shortDate: "10/19/24",
    name: "Bar Lubitsch",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/barlubitsch/">Bar Lubitsch</a>`,
    timeDetails: [
      // `<span>Doors - 8:00pm</span>`,
      `<span>The Late Nights</span>`,
      `<span>Brett Kohler</span>`,
      `<span><b>Super OK</b></span>`,
      `<span>Nature Loves Courage</span>`,
    ],
    address: `7702 Santa Monica Blvd, West Hollywood, CA 90046`,
    shortLocation: 'West Hollywood, CA',
    addressLink: `https://maps.app.goo.gl/bpgv3iDMGzY6cC6E7`,
  },
  'redwood-bar-10.25.24': {
    id: 'redwood-bar-10.25.24',
    imgSrc: 'redwood-bar-10.25.24.png',
    date: "10/25/2024",
    shortDate: "10/25/24",
    name: "Redwood Bar",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/theredwoodbar">Redwood Bar</a>`,
    timeDetails: [
      // `<span>Doors - 8:00pm</span>`,
      `<span>Limit State</span>`,
      `<span><b>Super OK</b></span>`,
      `<span>Strictly Elizabeth</span>`,
      `<span>Aster Baby and the Wild Rabbits</span>`,
    ],
    address: `316 W. 2nd St Los Angeles, CA 90012`,
    shortLocation: 'Los Angeles, CA',
    addressLink: `https://maps.app.goo.gl/mVD8Bd5zftUjkSdH7`,
  },
  'rivers-end-pub-11.2.24': {
    id: 'rivers-end-pub-11.2.24',
    imgSrc: 'rivers-end-pub-11.2.24.png',
    date: "11/02/2024",
    shortDate: "11/2/24",
    name: "River's End Pub",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/riversendpub/">River's End Pub</a>`,
    /*
    timeDetails: [
      // `<span>Doors - 8:00pm</span>`,
      `<span>Limit State</span>`,
      `<span><b>Super OK</b></span>`,
      `<span>Strictly Elizabeth</span>`,
      `<span>Aster Baby and the Wild Rabbits</span>`,
    ],
    */
    address: `13130 Soledad Canyon Rd, Santa Clarita, CA 91390`,
    shortLocation: 'Santa Clarita, CA',
    addressLink: `https://maps.app.goo.gl/vcmN48ovawMVsFg79`,
  },
};

export const pastShowList = [
  pastShowDict['rivers-end-pub-11.2.24'],
  pastShowDict['redwood-bar-10.25.24'],
  pastShowDict['bar-lubitsch-10.19.24'],
  pastShowDict['harvelles-revival-7.26.24'],
  pastShowDict['boomfest-6.29'],
  pastShowDict['molly-malones-4.25'],
  pastShowDict['boomfest-3.30'],
  pastShowDict['molly-malones-12.15'],
  pastShowDict['buds-fest-10.7'],
  pastShowDict['harvelles-revival-7.28'],
  pastShowDict['the-whisky-6.21'],
  pastShowDict['buds-of-bruin-zine-release-5.29'],
  pastShowDict['the-viper-room-5.18.23'],
  pastShowDict['breaking-sound-at-harvard-and-stone-3.2.23'],
  pastShowDict['el-cid-11.17'],
  pastShowDict['the-whisky-10.15'],
  pastShowDict['state-weho-9.9'],
  pastShowDict['molly-malones-8.20'],
  pastShowDict['barrel-tavern-7.30'],
  pastShowDict['harvard-and-stone-7.19'],
  pastShowDict['the-mint-6.18'],
];
